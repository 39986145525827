export type Prettify<T> = {
  [K in keyof T]: T[K]
} & unknown

export type OptionalKeys<T, K extends keyof T> = Omit<T, K> &
  Partial<Pick<T, K>>
export type RequiredKeys<T, K extends keyof T> = Omit<T, K> &
  Required<Pick<T, K>>

export type ElementTypeFromArray<T> = T extends (infer U)[] ? U : never

export enum PayBasis {
  'Hourly' = 'Hourly',
  'Yearly' = 'Yearly',
}

export enum SigninMethod {
  phone = 'phone',
  email = 'email',
}
