import { BoltIcon } from '@heroicons/react/24/solid'

import { BadgeButton, BadgeColor, PlusIcon, Text } from 'src/atoms'

export function RecommendedSkills({
  existingSkillsNames,
  handleAddNewSkill,
  initialRecommendedSkillsNames,
  loading,
  recommendedSkillsTitle = 'Laborup Suggestions',
}: {
  existingSkillsNames: string[]
  handleAddNewSkill: (skillName: string) => void
  initialRecommendedSkillsNames: string[]
  loading: boolean
  recommendedSkillsTitle?: string
}) {
  let recommendedSkills = initialRecommendedSkillsNames

  recommendedSkills = Array.from(new Set(recommendedSkills))
  recommendedSkills =
    recommendedSkills
      .filter((skill) => !existingSkillsNames.includes(skill))
      .filter((skill) => skill.split(' ').length <= 3)
      .sort() || []

  return (
    <>
      {recommendedSkills.length > 0 && (
        <div className="rounded border border-gray-400 bg-gray-50 px-4 py-3 md:px-6 md:py-4">
          <div className="mb-2.5 flex items-center">
            <Text weight="semibold" className="text-md text-gray-950">
              <span className="flex items-center">
                <BoltIcon className="text-primary-700 me-1.5 h-5 w-5 shrink-0" />
                {recommendedSkillsTitle}
              </span>
            </Text>
          </div>
          <div className="d-flex flex-wrap gap-3">
            {recommendedSkills.map((skill, indx) => (
              <BadgeButton
                key={indx}
                color={BadgeColor.gray}
                disabled={loading}
                onClick={() => handleAddNewSkill(skill)}
              >
                {skill}
                <PlusIcon className="h-4 w-4 text-gray-950" />
              </BadgeButton>
            ))}
          </div>
        </div>
      )}
    </>
  )
}
