import { ParsedResume } from 'types/graphql'

import type {
  CellSuccessProps,
  CellFailureProps,
  TypedDocumentNode,
} from '@redwoodjs/web'

import { Card, Spinner, Text } from 'src/atoms'
import CompleteProfileCell from 'src/components/ResumeImport/CompleteProfile/CompleteProfileCell'

type ParseResumeQuery = {
  parsedResume: ParsedResume
}

type ParseResumeQueryVariables = {
  base64Resume: string
}

export const QUERY: TypedDocumentNode<
  ParseResumeQuery,
  ParseResumeQueryVariables
> = gql`
  query ParseResumeQuery($base64Resume: String!) {
    parsedResume: parseResume(base64Resume: $base64Resume) {
      skills {
        name
      }
      industries {
        name
      }
      certifications {
        name
        organization
        startMonth
        startYear
        endMonth
        endYear
        credentialId
        credentialUrl
      }
      workExperiences {
        title
        company
        location
        startMonth
        startYear
        endMonth
        endYear
        currentPosition
        descriptions
      }
      educations {
        school
        degree
        location
        startMonth
        startYear
        endMonth
        endYear
        current
      }
    }
  }
`

export const Loading = () => (
  <div className="flex flex-col gap-6">
    <div>
      <Text weight="bold" className="text-2xl">
        Let&apos;s speed things up
      </Text>
      <Text className="text-md text-gray-900">
        We&apos;ll use your resume to fill out your profile
      </Text>
    </div>
    <Card className="flex flex-col items-center gap-4 px-8 py-24 !bg-primary-200">
      <Spinner className="w-6 h-6" />
      <div className="flex flex-col gap-[6px] text-center">
        <Text weight="bold" className="text-2xl">
          Reading your resume...
        </Text>
        <Text className="text-sm">
          We&apos;ll automatically refresh the page when everything has been
          imported.
        </Text>
      </div>
    </Card>
  </div>
)

// * Removed `Empty` because it should not be used in this context.

export const Failure = ({ error }: CellFailureProps) => (
  <div className="rw-cell-error">{error?.message}</div>
)

export const Success = ({
  parentDialogScrollableId,
  parsedResume,
}: CellSuccessProps<ParseResumeQuery, ParseResumeQueryVariables> & {
  base64Resume: string
  parentDialogScrollableId: string
}) => {
  return (
    <CompleteProfileCell
      parentDialogScrollableId={parentDialogScrollableId}
      parsedResume={parsedResume}
    />
  )
}
