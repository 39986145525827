const monthNameToMM = {
  January: '01',
  February: '02',
  March: '03',
  April: '04',
  May: '05',
  June: '06',
  July: '07',
  August: '08',
  September: '09',
  October: '10',
  November: '11',
  December: '12',
}

export const dates = {
  /**
   * Returns whether a start date is before or equal to an end date.
   *
   * Returns `true` if either the start date or end date is not set.
   */
  isStartDateBeforeOrEqualToEndDate: (
    startMonth?: string,
    startYear?: string,
    endMonth?: string,
    endYear?: string
  ): boolean => {
    // If either the start date or end date is not set, we can't
    // compare them and therefore the ordering is valid.
    if (!startMonth || !startYear || !endMonth || !endYear) {
      return true
    }

    try {
      // We must construct the dates in this way to ensure consistent comparison across browsers.
      const startDate = new Date(
        `${startYear}-${monthNameToMM[startMonth]}-01T00:00:00Z`
      )
      const endDate = new Date(
        `${endYear}-${monthNameToMM[endMonth]}-01T00:00:00Z`
      )
      return startDate <= endDate
    } catch (error) {
      // If we can't construct/compare the dates, let's just assume
      // that the ordering is valid so as to not obstruct the user.
      console.error(error)
      return true
    }
  },
}
