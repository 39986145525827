import { useMemo } from 'react'

import { Select, SelectProps } from './Select'

const DEFAULT_START_YEAR = 1950
const DEFAULT_END_YEAR = new Date().getFullYear()

export type SelectYearProps = SelectProps & {
  endYear?: number
}

export const SelectYear = React.forwardRef<HTMLSelectElement, SelectYearProps>(
  function SelectYear({ endYear = DEFAULT_END_YEAR, ...props }, ref) {
    const yearOptions = useMemo(() => {
      return Array.from(
        { length: endYear - DEFAULT_START_YEAR + 1 },
        (_, i) => ({
          label: (endYear - i).toString(),
          value: (endYear - i).toString(),
        })
      )
    }, [endYear])

    return (
      <Select ref={ref} {...props}>
        {yearOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    )
  }
)
