import { EditSkillsInputDynamicVariants } from 'types/index'

import { Text } from 'src/atoms'
import { RecommendedSkills } from 'src/components/RecommendedSkills/RecommendedSkills'
import { Shimmer } from 'src/components/Shimmer/Shimmer'

export const QUERY = gql`
  query GET_SKILL_RECCOMENDATIONS(
    $variant: String!
    $desiredPosition: String
    $shouldOnlyRecommendSoftSkills: Boolean
  ) {
    choices: getRecommendedSkills(
      variant: $variant
      desiredPosition: $desiredPosition
      shouldOnlyRecommendSoftSkills: $shouldOnlyRecommendSoftSkills
    ) {
      choices
    }
  }
`

export const beforeQuery = ({
  editSkillsInput,
}: {
  editSkillsInput: EditSkillsInputDynamicVariants
}) => {
  return {
    variables: {
      ...editSkillsInput,
    },
  }
}

export const Loading = () => <Shimmer className="w-full h-32 md:h-16" />
export const Empty = () => (
  <Text weight="semibold" className="text-sm text-gray-700">
    Empty
  </Text>
)

export const Failure = ({ error }) => (
  <Text weight="semibold" className="text-sm text-red-800">
    Error: {error?.message}
  </Text>
)

export const Success = ({
  choices,
  handleAddNewSkill,
  existingSkillsNames,
  loading,
}: {
  choices?: { choices: string[] }
  handleAddNewSkill: (skillName: string) => void
  existingSkillsNames: string[]
  loading: boolean
  editSkillsInput: EditSkillsInputDynamicVariants
}) => {
  // TODO: A nice future improvement would be to generate more
  // skills on the fly if all of the recommended skills have
  // been added.
  return (
    <RecommendedSkills
      existingSkillsNames={existingSkillsNames}
      handleAddNewSkill={handleAddNewSkill}
      initialRecommendedSkillsNames={choices?.choices || []}
      loading={loading}
    />
  )
}
