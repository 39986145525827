import React from 'react'

import clsx from 'clsx'

import { Link as RouterLink } from '@redwoodjs/router'

import { LinkProps } from './types'

export const Link = React.forwardRef(function (
  { children, href, className, onClick, ...props }: LinkProps,
  ref: React.ForwardedRef<HTMLAnchorElement>
) {
  // If the link is external, we use an anchor tag, otherwise we use
  // the RouterLink component (which doesn't support external links).
  const isExternalLink = href.startsWith('http')

  if (isExternalLink) {
    return (
      <a
        {...props}
        ref={ref}
        onClick={onClick}
        className={clsx(
          'relative outline-none after:pointer-events-none after:absolute after:rounded after:-inset-[1px] after:ring-inset after:focus-within:ring-2 after:focus-within:ring-primary-700',
          className
        )}
        rel="noopener noreferrer"
        target="_blank"
        href={href}
      >
        {children}
      </a>
    )
  }
  return (
    <RouterLink
      {...props}
      ref={ref}
      onClick={onClick}
      className={clsx(
        'relative outline-none after:pointer-events-none after:absolute after:rounded after:-inset-[1px] after:ring-inset after:focus-within:ring-2 after:focus-within:ring-primary-700',
        className
      )}
      to={href}
    >
      {children}
    </RouterLink>
  )
})
