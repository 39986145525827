import React, { useCallback } from 'react'

import {
  FieldValues,
  useFormContext,
  Path,
  PathValue,
  Controller,
} from 'react-hook-form'

import { SelectMonth, SelectProps, ErrorMessage } from 'src/atoms'

export type RHFSelectMonthProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>
  description?: React.ReactNode
  clearErrorOnChange?: boolean
} & Omit<SelectProps, 'value'>

export const RHFSelectMonth = <TFieldValues extends FieldValues>({
  name,
  description,
  onChange: handleChangeProps,
  clearErrorOnChange,
  ...props
}: RHFSelectMonthProps<TFieldValues>) => {
  const { control, setValue, clearErrors } = useFormContext<TFieldValues>()

  const handleClear = useCallback(() => {
    const val = '' as PathValue<TFieldValues, Path<TFieldValues>>
    setValue(name, val, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    })
    handleChangeProps?.(val)
  }, [name, setValue, handleChangeProps])

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange: handleChangeField, ...field },
        fieldState: { error },
      }) => {
        const handleChange: React.ChangeEventHandler<HTMLSelectElement> = (
          e
        ) => {
          if (clearErrorOnChange) {
            clearErrors(name)
            setValue(
              name,
              e.target.value as PathValue<TFieldValues, Path<TFieldValues>>
            )
          } else {
            handleChangeField(e)
          }
          handleChangeProps?.(e)
        }

        return (
          <>
            <SelectMonth
              {...props}
              {...field}
              onChange={handleChange}
              onClear={handleClear}
            />
            {description}
            <ErrorMessage className="ms-1.5" message={error?.message} />
          </>
        )
      }}
    />
  )
}
