import {
  EmploymentStatusesQuery,
  EmploymentStatusesQueryVariables,
} from 'types/graphql'

import type {
  CellSuccessProps,
  CellFailureProps,
  TypedDocumentNode,
} from '@redwoodjs/web'

import { RHFSelect, RHFSelectProps } from 'src/reactHookForm'

// * BE should return statuses sorted by ordinal in ascending order.

export const QUERY: TypedDocumentNode<
  EmploymentStatusesQuery,
  EmploymentStatusesQueryVariables
> = gql`
  query EmploymentStatusesQuery {
    employmentStatuses {
      id
      title
    }
  }
`

// * We do not want to show anything while loading since this is a select input.
export const Loading = () => null

// * Removed `Empty` as it should never be used.

export const Failure = ({ error }: CellFailureProps) => (
  <>{error ? <div className="rw-cell-error">{error.message}</div> : null}</>
)

export const Success = ({
  employmentStatuses,
  updating: _updating,
  queryResult: _queryResult,
  ...selectProps
}: CellSuccessProps<EmploymentStatusesQuery, EmploymentStatusesQueryVariables> &
  RHFSelectProps) => {
  return (
    <RHFSelect {...selectProps}>
      {employmentStatuses.map((employmentStatus) => (
        <option key={employmentStatus.id} value={employmentStatus.id}>
          {employmentStatus.title}
        </option>
      ))}
    </RHFSelect>
  )
}
