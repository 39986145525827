import React from 'react'

type SVGProps = React.SVGProps<never>

export const ChevronUpIcon = (props: SVGProps) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    stroke="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7803 10.1672C11.4874 10.4601 11.0126 10.4601 10.7197 10.1672L8 7.4475L5.28033 10.1672C4.98744 10.4601 4.51256 10.4601 4.21967 10.1672C3.92678 9.87428 3.92678 9.3994 4.21967 9.10651L7.46967 5.85651C7.76256 5.56362 8.23744 5.56362 8.53033 5.85651L11.7803 9.10651C12.0732 9.3994 12.0732 9.87428 11.7803 10.1672Z"
      fill="currentColor"
    />
  </svg>
)
