import { useMutation } from '@redwoodjs/web'

const CREATE_RESUME_FILE_MUTATION = gql`
  mutation CreateResumeFileMutation($input: CreateResumeFileInput!) {
    createResumeFile(input: $input) {
      id
    }
  }
`
export function useCreateResumeFile<
  TData = any,
  TVariables = GraphQLOperationVariables
>(options?: GraphQLMutationHookOptions<TData, TVariables>) {
  return useMutation(CREATE_RESUME_FILE_MUTATION, options)
}
