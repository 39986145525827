import { createContext, useContext, useEffect, useState } from 'react'

import {
  navigate,
  routes,
  useLocation,
  useParams,
  useRoutePath,
  useRoutePaths,
} from '@redwoodjs/router'

import { Button } from 'src/atoms'
import { useAuth } from 'src/auth'
import CompaniesBanner from 'src/components/CompaniesBanner/CompaniesBanner'
import LaborupLogo from 'src/components/LaborupLogo/LaborupLogo'
import Testimonial from 'src/components/Testimonial/Testimonial'
import { signupUtils } from 'src/lib/utils/signup/signup'

const OnboardingContext = createContext<{
  setBannerStep: (step: number) => void
} | null>(null)

const SigninSignupLayout = ({ children }) => {
  const { isAuthenticated, hasRole } = useAuth()
  const { search, pathname } = useLocation()
  const { endorsementHash } = useParams()
  const queryParams = new URLSearchParams(search)
  const redirectTo = queryParams.get('redirectTo')

  useEffect(() => {
    if (isAuthenticated) {
      if (hasRole('employer') || hasRole('admin')) {
        navigate(routes.courses())
      } else {
        const jobRedirectPath =
          signupUtils.getAndClearJobRedirectPathFromSessionStorage()
        if (jobRedirectPath) {
          navigate(jobRedirectPath)
          return
        }

        navigate(
          redirectTo ||
            (pathname === '/signup' && !endorsementHash
              ? routes.profile()
              : routes.home({ ...(endorsementHash && { endorsementHash }) }))
        )
      }
    }
  }, [isAuthenticated, hasRole, redirectTo, pathname])

  // Check if the current path is login.
  const paths = useRoutePaths()
  const isLogin = useRoutePath() === paths.login

  const handleNavigation = (route) => {
    if (redirectTo) {
      navigate(route + '?redirectTo=' + encodeURIComponent(redirectTo))
    } else {
      navigate(route)
    }
  }

  const [bannerStep, setBannerStep] = useState(0)

  const renderBanner = () => {
    switch (bannerStep) {
      case 0:
        return <Testimonial step={0} />
      case 1:
        return <CompaniesBanner />
      case 2:
        return <Testimonial step={1} />
      default:
        return <CompaniesBanner />
    }
  }

  return (
    <OnboardingContext.Provider
      value={{
        setBannerStep,
      }}
    >
      <main className="min-h-screen flex flex-col">
        <div className="px-8 py-4 flex justify-between border-b border-gray-400">
          <a
            className="flex justify-center items-center"
            href="https://www.laborup.com"
          >
            <LaborupLogo className="h-8 w-auto" />
          </a>
          {isLogin ? (
            <Button onClick={() => handleNavigation(routes.signup())} outline>
              Sign up
            </Button>
          ) : (
            <Button onClick={() => handleNavigation(routes.login())} outline>
              Log in
            </Button>
          )}
        </div>
        <div className="flex flex-1">
          <div className="flex-[7] p-6 flex justify-start md:justify-center items-center flex-col bg-gray-50">
            {children}
          </div>
          <div className="flex-[5] bg-gray-950 hidden md:flex items-center justify-center">
            {renderBanner()}
          </div>
        </div>
      </main>
    </OnboardingContext.Provider>
  )
}

export const useOnboardingContext = () => {
  const context = useContext(OnboardingContext)
  if (!context) {
    throw new Error(
      'useOnboardingContext must be used within an OnboardingContextProvider'
    )
  }
  return context
}

export default SigninSignupLayout
