import { useCallback, useMemo } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import type {
  CreateEndorsementMutation,
  CreateEndorsementMutationVariables,
  NewEndorsementDialogQuery,
  NewEndorsementDialogQueryVariables,
} from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import {
  type CellSuccessProps,
  type CellFailureProps,
  type TypedDocumentNode,
  useMutation,
} from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import {
  Banner,
  BannerDescription,
  BannerTitle,
  Button,
  Card,
  Dialog,
  DialogBody,
  DialogTitle,
  Field,
  FieldGroup,
  Fieldset,
  Label,
  RequiredAsterisk,
  Text,
} from 'src/atoms'
import { endorsementsUtils } from 'src/lib/utils/endorsements/endorsements'
import { RHFSelect, RHFSelectProps, RHFTextarea } from 'src/reactHookForm'
import { EndorsementSchema, endorsementSchema } from 'src/schemas'

export const QUERY: TypedDocumentNode<
  NewEndorsementDialogQuery,
  NewEndorsementDialogQueryVariables
> = gql`
  query NewEndorsementDialogQuery($endorsementHash: String!) {
    endorseeProfile: findProfileToEndorse(endorsementHash: $endorsementHash) {
      id
      firstName
      lastName
      desiredPosition
    }
  }
`

// TODO: Can we just return empty?
const CREATE_ENDORSEMENT_MUTATION: TypedDocumentNode<
  CreateEndorsementMutation,
  CreateEndorsementMutationVariables
> = gql`
  mutation CreateEndorsementMutation($input: CreateEndorsementInput!) {
    createEndorsement(input: $input) {
      technicalExperienceRating
      teamworkRating
      leadershipRating
      additionalContext
      isVisible
      createdAt
    }
  }
`

const defaultValues = {
  technicalExperienceRating: null,
  teamworkRating: null,
  leadershipRating: null,
  additionalContext: '',
}

type EndorsementRatingInputProps = Pick<RHFSelectProps, 'name'> & {
  label: React.ReactNode
}

const EndorsementRatingInput = ({
  label,
  name,
}: EndorsementRatingInputProps) => {
  return (
    <Field>
      <Label>
        {label}
        <RequiredAsterisk />
      </Label>
      <RHFSelect name={name} placeholder="Select a rating">
        {[1, 2, 3, 4, 5].map((rating) => {
          return (
            <option key={rating} value={rating}>
              {rating}
            </option>
          )
        })}
      </RHFSelect>
    </Field>
  )
}

export const Loading = () => null

export const Failure = ({
  error,
}: CellFailureProps<NewEndorsementDialogQueryVariables>) => (
  <Dialog onClose={() => {}} open>
    <DialogTitle showCloseIcon={false}>Something went wrong!</DialogTitle>
    <DialogBody className="flex flex-col gap-6">
      <Card className="!bg-red-200">{error?.message}</Card>
      <Text>
        Please contact us using the <b>Support</b> option in the top-right
        corner if you believe this message was shown to you in error
      </Text>
      <Button className="w-full" onClick={() => navigate(routes.home())}>
        Close
      </Button>
    </DialogBody>
  </Dialog>
)

export const Success = ({
  endorseeProfile,
  endorsementHash,
}: CellSuccessProps<
  NewEndorsementDialogQuery,
  NewEndorsementDialogQueryVariables
> & {
  endorsementHash: string
}) => {
  const methods = useForm({
    defaultValues,
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver<EndorsementSchema>(endorsementSchema),
  })

  const [createEndorsement, { loading }] = useMutation(
    CREATE_ENDORSEMENT_MUTATION
  )

  const isValidDesiredPosition = useMemo(() => {
    return endorsementsUtils.isValidDesiredPosition(
      endorseeProfile.desiredPosition || ''
    )
  }, [endorseeProfile.desiredPosition])

  const titleText = useMemo(() => {
    return isValidDesiredPosition
      ? `You are endorsing ${endorseeProfile.firstName} ${endorseeProfile.lastName} as a ${endorseeProfile.desiredPosition}`
      : `You are endorsing ${endorseeProfile.firstName} ${endorseeProfile.lastName}`
  }, [
    endorseeProfile.desiredPosition,
    endorseeProfile.firstName,
    endorseeProfile.lastName,
    isValidDesiredPosition,
  ])

  const technicalExperienceLabel = useMemo(() => {
    return isValidDesiredPosition
      ? `On a scale of 1 to 5, with 1 being poor and 5 being excellent, how would you rate their technical experience as a ${endorseeProfile.desiredPosition}?`
      : 'On a scale of 1 to 5, with 1 being poor and 5 being excellent, how would you rate their technical experience?'
  }, [endorseeProfile.desiredPosition, isValidDesiredPosition])

  const handleSubmit = useCallback(
    async (values: EndorsementSchema) => {
      createEndorsement({
        variables: {
          input: {
            ...values,
            additionalContext: values.additionalContext || null,
            endorsementHash,
            isValidDesiredPosition,
          },
        },
        onCompleted: () => {
          toast.success('Endorsement submitted!')
          navigate(routes.home()) // This effectively clears the endorsement hash from the URL which closes the modal.
        },
        onError: () => {
          toast.error('Failed to submit endorsement!')
        },
      })
    },
    [createEndorsement, endorsementHash, isValidDesiredPosition]
  )

  return (
    <Dialog
      open={true}
      onClose={() => {}} // This prevents the dialog from being manually closed.
    >
      {/* TODO: Remove !text-wrap if we remove text-balance from <DialogTitle>. */}
      <DialogTitle className="!text-wrap" showCloseIcon={false}>
        {titleText}
      </DialogTitle>
      <DialogBody className="flex flex-col gap-2">
        <Banner>
          <BannerTitle>Important</BannerTitle>
          <BannerDescription>
            {endorseeProfile.firstName} will be able to see your endorsement.
          </BannerDescription>
        </Banner>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)}>
            <Fieldset className="mt-6">
              <FieldGroup>
                <EndorsementRatingInput
                  label={technicalExperienceLabel}
                  name="technicalExperienceRating"
                />
                <EndorsementRatingInput
                  label="On a scale of 1 to 5, with 1 being poor and 5 being
                        excellent, how would you rate their teamwork?"
                  name="teamworkRating"
                />
                <EndorsementRatingInput
                  label="On a scale of 1 to 5, with 1 being poor and 5 being
                        excellent, how would you rate their leadership?"
                  name="leadershipRating"
                />
                <Field>
                  <div className="flex flex-col gap-2">
                    <Label>Anything else you&apos;d like to add?</Label>
                    <div>
                      <RHFTextarea name="additionalContext" />
                    </div>
                  </div>
                </Field>
              </FieldGroup>
            </Fieldset>
            <Button className="w-full" disabled={!!loading} type="submit">
              Submit Endorsement
            </Button>
          </form>
        </FormProvider>
      </DialogBody>
    </Dialog>
  )
}
