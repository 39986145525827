import React from 'react'

import {
  FieldValues,
  useFormContext,
  Path,
  Controller,
  PathValue,
} from 'react-hook-form'

import { Input, InputProps, ErrorMessage } from 'src/atoms'

export type RHFInputProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>
  clearErrorOnChange?: boolean
  description?: React.ReactNode
} & Omit<InputProps, 'value'>

const Component = <TFieldValues extends FieldValues>({
  name,
  description,
  onChange: handleChangeProps,
  clearErrorOnChange,
  ...props
}: RHFInputProps<TFieldValues>) => {
  const { control, clearErrors, setValue } = useFormContext<TFieldValues>()

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange: handleChangeField, ...field },
        fieldState: { error },
      }) => {
        const handleChange: React.ChangeEventHandler<HTMLInputElement> = (
          e
        ) => {
          if (clearErrorOnChange) {
            clearErrors(name)
            setValue(
              name,
              e.target.value as PathValue<TFieldValues, Path<TFieldValues>>
            )
          } else {
            handleChangeField(e)
          }
          handleChangeProps?.(e)
        }

        return (
          <>
            <Input {...field} {...props} onChange={handleChange} />
            {description}
            <ErrorMessage className="ms-1.5" message={error?.message} />
          </>
        )
      }}
    />
  )
}

export const RHFInput = React.memo(Component) as typeof Component
