import { routes } from '@redwoodjs/router'

import {
  adminOnlySources,
  googleSearchAdSource,
  JOB_REDIRECT_PATH_SESSION_STORAGE_KEY,
  sourceOptions,
} from 'src/lib/constants'

export const signupUtils = {
  /**
   * Given a `name`, extracts the first and last name.
   */
  extractFirstAndLastName: (name: string) => {
    const nameSplit = name.trim().split(' ')

    // * We expect the name to have at least two parts,
    // * but in case it doesn't, let's throw an error.
    if (nameSplit.length < 2) {
      throw new Error('Name must have at least two parts')
    }

    return {
      firstName: nameSplit[0],
      lastName: nameSplit.slice(1).join(' '),
    }
  },

  /**
   * Checks if the `source` is a valid source option.
   */
  isValidSource: (source: string) => {
    if (adminOnlySources.includes(source)) {
      return true
    }

    for (const option of sourceOptions) {
      if (option.label === source && option.value === source) {
        return true
      }
    }
    return false
  },

  /**
   * Checks if the `source` is the special Google Search Ad source.
   */
  isGoogleSearchAdSource: (source: string) => {
    return source === googleSearchAdSource
  },

  /**
   * Sets the job redirect path in session storage for use after signup.
   */
  setJobRedirectPathInSessionStorage: ({ jobId }: { jobId: string }) => {
    sessionStorage.setItem(
      JOB_REDIRECT_PATH_SESSION_STORAGE_KEY,
      routes.laborupJob({ id: jobId })
    )
  },

  /**
   * Gets and clears the job redirect path from session storage.
   */
  getAndClearJobRedirectPathFromSessionStorage: (): string | null => {
    const jobRedirectPath = sessionStorage.getItem(
      JOB_REDIRECT_PATH_SESSION_STORAGE_KEY
    )
    sessionStorage.removeItem(JOB_REDIRECT_PATH_SESSION_STORAGE_KEY)
    return jobRedirectPath
  },
}
