import React, { useEffect, useMemo, useState } from 'react'

import { Button as HeadlessButton } from '@headlessui/react'
import clsx from 'clsx'

import { TouchTarget } from '../Button/Button'
import { Link } from '../Link/Link'

import { AvatarButtonProps, AvatarLinkProps, AvatarProps } from './types'

export function Avatar({
  src = null,
  square = false,
  initials,
  alt = '',
  className,
  children,
  deriveInitials,
  imgClassName,
  size = 'size-8',
  maxInitials = 10,
  ...props
}: AvatarProps) {
  const [source, setSource] = useState(src)
  useEffect(() => {
    setSource(src)
  }, [src])

  const showInitials = !source

  const derivedInitials = useMemo(() => {
    if (!showInitials) return
    if (!deriveInitials) return initials
    return initials
      ?.split(' ')
      ?.map((name) => name?.[0]?.toUpperCase?.())
      ?.join('')
      ?.slice(0, maxInitials)
  }, [showInitials, initials, deriveInitials, maxInitials])

  return (
    <span
      data-slot="avatar"
      className={clsx(
        className,
        size,
        `${
          showInitials
            ? 'inline-grid bg-primary-700 align-middle *:col-start-1 *:row-start-1'
            : 'inline-flex items-center bg-transparent'
        } text-white`,
        square ? 'rounded-[20%] *:rounded-[20%]' : 'rounded-full *:rounded-full'
      )}
      {...props}
    >
      {showInitials && (
        <svg
          className="fill-current select-none text-[48px] font-medium uppercase"
          viewBox="0 0 100 100"
          aria-hidden={alt ? undefined : 'true'}
        >
          {alt && <title>{alt}</title>}
          <text
            fill="white"
            x="50%"
            y="50%"
            alignmentBaseline="middle"
            dominantBaseline="middle"
            textAnchor="middle"
            dy=".125em"
          >
            {derivedInitials || ''}
          </text>
        </svg>
      )}
      {children ? children : null}
      {source && (
        <img
          className={clsx(imgClassName, size, 'object-contain')}
          onError={() => setSource(null)}
          src={source}
          alt={alt}
        />
      )}
    </span>
  )
}

export const AvatarButton = React.forwardRef(function (
  {
    src,
    square = false,
    initials,
    alt,
    className,
    size,
    ...props
  }: AvatarButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const classes = clsx(
    className,
    square ? 'rounded-lg' : 'rounded-full',
    'relative focus:outline-none data-[focus]:outline data-[focus]:outline-2 data-[focus]:outline-offset-2 data-[focus]:outline-blue-500'
  )

  return (
    <HeadlessButton {...props} className={classes} ref={ref}>
      <TouchTarget>
        <Avatar
          size={size}
          src={src}
          square={square}
          initials={initials}
          alt={alt}
        />
      </TouchTarget>
    </HeadlessButton>
  )
})

export const AvatarLink = React.forwardRef(function (
  {
    src,
    square = false,
    initials,
    alt,
    className,
    size,
    ...props
  }: AvatarLinkProps,
  ref: React.ForwardedRef<HTMLAnchorElement>
) {
  const classes = clsx(
    className,
    square ? 'rounded-lg' : 'rounded-full',
    'relative focus:outline-none data-[focus]:outline data-[focus]:outline-2 data-[focus]:outline-offset-2 data-[focus]:outline-blue-500'
  )

  return (
    <Link {...props} className={classes} ref={ref}>
      <TouchTarget>
        <Avatar
          size={size}
          src={src}
          square={square}
          initials={initials}
          alt={alt}
        />
      </TouchTarget>
    </Link>
  )
})
