import React from 'react'

import clsx from 'clsx'

import { Badge, BadgeColor } from 'src/atoms'
import './Shimmer.css'

export function Shimmer({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'div'>) {
  return (
    <div className={clsx(className, 'container rounded-sm')} {...props}>
      <div className="shimmer" />
    </div>
  )
}

export function ShimmerBadge({
  animationDuration,
  children,
  className,
  ...props
}: React.ComponentPropsWithoutRef<'div'> & {
  animationDuration?: string
  color?: BadgeColor
}) {
  return (
    <Badge className={clsx(className, 'container')} {...props}>
      {children}
      <div
        className="shimmer"
        style={{ ...(animationDuration && { animationDuration }) }}
      />
    </Badge>
  )
}
