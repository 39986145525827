import React from 'react'

import clsx from 'clsx'

import { Text } from '../Text/Text'

type Props = {
  className?: string
  message: React.ReactNode
}

export const ErrorMessage = ({ message, className }: Props) => (
  <>
    {message ? (
      <Text
        weight="medium"
        className={clsx(className, 'text-xs text-red-800 py-[1px]')}
      >
        {message}
      </Text>
    ) : (
      <p className="h-5" />
    )}
  </>
)
