import { AcademicCapIcon } from '@heroicons/react/24/solid'
import { Tooltip } from 'react-tooltip'

import { useLocation, routes } from '@redwoodjs/router'

import { TopNavbar } from 'src/components/TopNavbar/TopNavbar'
const AdminLayout = ({ children }) => {
  const { pathname } = useLocation()

  const navigationItems = [
    {
      name: 'Courses',
      to: routes.courses(),
      current: pathname === routes.courses(),
      icon: AcademicCapIcon,
      children: [],
    },
  ]

  return (
    <main className="bg-gray-50">
      <TopNavbar navigationItems={navigationItems} />

      <div className="min-h-screen pt-14 flex justify-center">
        <div className="flex flex-col justify-center gap-6 p-4 md:min-w-[calc(100%-8rem)] md:flex-row md:p-8">
          <div className="mb-4 grow md:max-w-7xl">{children}</div>
        </div>
      </div>
      <Tooltip id="set-as-primary-tooltip" className="hidden md:block" />
    </main>
  )
}

export default AdminLayout
