import React from 'react'

type SVGProps = React.SVGProps<never>

export const PlusIcon = (props: SVGProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    stroke="currentColor"
    {...props}
  >
    <path
      d="M8.75 4.13684C8.75 3.72263 8.41421 3.38684 8 3.38684C7.58579 3.38684 7.25 3.72263 7.25 4.13684V7.63684H3.75C3.33579 7.63684 3 7.97263 3 8.38684C3 8.80105 3.33579 9.13684 3.75 9.13684L7.25 9.13684V12.6368C7.25 13.0511 7.58579 13.3868 8 13.3868C8.41421 13.3868 8.75 13.0511 8.75 12.6368V9.13684L12.25 9.13684C12.6642 9.13684 13 8.80105 13 8.38684C13 7.97263 12.6642 7.63684 12.25 7.63684H8.75V4.13684Z"
      fill="currentColor"
    />
  </svg>
)
