import React from 'react'

import {
  Checkbox as HeadlessCheckbox,
  Field as HeadlessField,
  type CheckboxProps as HeadlessCheckboxProps,
  type FieldProps as HeadlessFieldProps,
} from '@headlessui/react'
import { clsx } from 'clsx'
import { useField, useFormikContext } from 'formik'

export function CheckboxGroup({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'div'>) {
  return (
    <div
      data-slot="control"
      {...props}
      className={clsx(
        className,

        // Basic groups
        'space-y-3',

        // With descriptions
        'has-[[data-slot=description]]:space-y-6 [&_[data-slot=label]]:has-[[data-slot=description]]:font-medium'
      )}
    />
  )
}

export function CheckboxField({ className, ...props }: HeadlessFieldProps) {
  return (
    <HeadlessField
      data-slot="field"
      {...props}
      className={clsx(
        className,

        // Base layout
        'grid grid-cols-[1.125rem_1fr] items-center gap-x-3 gap-y-1 sm:grid-cols-[1rem_1fr]',

        // Control layout
        '[&>[data-slot=control]]:col-start-1 [&>[data-slot=control]]:row-start-1 [&>[data-slot=control]]:justify-self-center',

        // Label layout
        '[&>[data-slot=label]]:col-start-2 [&>[data-slot=label]]:row-start-1 [&>[data-slot=label]]:justify-self-start',

        // Description layout
        '[&>[data-slot=description]]:col-start-2 [&>[data-slot=description]]:row-start-2',

        // With description
        '[&_[data-slot=label]]:has-[[data-slot=description]]:font-medium'
      )}
    />
  )
}

const base = [
  // Basic layout
  'relative isolate flex size-[1.125rem] items-center justify-center rounded-[0.3125rem] sm:size-4',

  // Background color + shadow applied to inset pseudo element, so shadow blends with border in light mode
  'before:absolute before:inset-0 before:-z-10 before:rounded-[calc(0.3125rem-1px)] before:bg-white before:shadow',

  // Background color when checked
  'before:group-data-[checked]:bg-[--checkbox-checked-bg]',

  // Border
  'border-2 border-gray-500 group-data-[checked]:border-primary-700 group-data-[checked]:group-data-[hover]:border-primary-700 group-data-[hover]:border-primary-700 group-data-[checked]:bg-[--checkbox-checked-border]',

  // Inner highlight shadow
  'after:absolute after:inset-0 after:rounded-[calc(0.3125rem-1px)] after:shadow-[inset_0_1px_theme(colors.white/15%)]',

  // Focus ring
  'group-data-[focus]:outline group-data-[focus]:outline-2 group-data-[focus]:outline-offset-2 group-data-[focus]:outline-primary-700',

  // Disabled state
  'group-data-[disabled]:border-gray-400 group-data-[disabled]:bg-gray-200 group-data-[disabled]:[--checkbox-check:theme(colors.gray.200)] group-data-[disabled]:before:bg-transparent',
]

const colors = {
  primary: [
    '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.primary.700)] [--checkbox-checked-border:theme(colors.primary.700)]',
  ],
}

type Color = keyof typeof colors

export type CheckboxProps = {
  color?: Color
} & HeadlessCheckboxProps

export const Checkbox = React.forwardRef<HTMLElement, CheckboxProps>(
  function Checkbox({ color = 'primary', className, ...props }, ref) {
    return (
      <HeadlessCheckbox
        ref={ref}
        data-slot="control"
        className={clsx(
          className,
          'group inline-flex rounded-[0.3125rem] transition-colors duration-150 ease-in-out focus:outline-none'
        )}
        {...props}
      >
        <span className={clsx([base, colors[color]])}>
          <svg
            className="size-4 stroke-[--checkbox-check] opacity-0 group-data-[checked]:opacity-100 sm:h-3.5 sm:w-3.5"
            viewBox="0 0 14 14"
            fill="none"
          >
            {/* Checkmark icon */}
            <path
              className="opacity-100 group-data-[indeterminate]:opacity-0"
              d="M3 8L6 11L11 3.5"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            {/* Indeterminate icon */}
            <path
              className="opacity-0 group-data-[indeterminate]:opacity-100"
              d="M3 7H11"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </HeadlessCheckbox>
    )
  }
)

export function CheckboxFormikField({ name, ...props }: CheckboxProps) {
  const { setFieldValue } = useFormikContext()
  const [field] = useField(name)

  const handleChange = (checked: boolean) => {
    setFieldValue(field.name, checked)
  }

  const defaultChecked = field.value
  return (
    <Checkbox
      name={name}
      onChange={handleChange}
      defaultChecked={defaultChecked}
      {...props}
    />
  )
}
