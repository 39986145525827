import { useEffect } from 'react'

import clsx from 'clsx'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { routes, useLocation, useRoutePath } from '@redwoodjs/router'

import { Link, Text } from 'src/atoms'
import { useScreenSize } from 'src/hooks/useResize'

type Anchor = {
  href: string
  matchingRoutePaths?: string[]
  label: string
}

const MobileNavigation = () => {
  const { pathname, hash } = useLocation()
  const fullPath = `${pathname}${hash}`
  const currentRoutePath = useRoutePath()

  const editBasicInfoRoutePath = useRoutePath('editBasicInfo')
  const editCertificationRoutePath = useRoutePath('editCertification')
  const editEducationRoutePath = useRoutePath('editEducation')
  const editIndustriesRoutePath = useRoutePath('editIndustries')
  const editSkillsRoutePath = useRoutePath('editSkills')
  const editWorkExperienceRoutePath = useRoutePath('editWorkExperience')
  const endorsementRoutePath = useRoutePath('endorsement')
  const newCertificationRoutePath = useRoutePath('newCertification')
  const newEducationRoutePath = useRoutePath('newEducation')
  const newEndorsementRoutePath = useRoutePath('newEndorsement')
  const newResumeRoutePath = useRoutePath('newResume')
  const newWorkExperienceRoutePath = useRoutePath('newWorkExperience')
  const resumesRoutePath = useRoutePath('resumes')
  const resumesUploadRoutePath = useRoutePath('resumesUpload')

  useEffect(() => {
    if (!hash) {
      return
    }

    const handleScroll = () => {
      const element = document.querySelector(hash)
      if (element) {
        // Scroll to the element with a small offset to account for the fixed header and swiper.
        const to = element.getBoundingClientRect().top + window.scrollY - 150
        window.scrollTo({ top: to, behavior: 'smooth' })
      }
    }

    // Small delay to ensure that the page has rendered before scrolling.
    // This is primarily used when the page is refreshed with a hash.
    const timeoutId = setTimeout(handleScroll, 250)
    return () => clearTimeout(timeoutId)
  }, [hash])

  const anchors: Anchor[] = [
    {
      href: `${routes.profile()}#profile`,
      label: 'Profile',
    },
    {
      href: `${routes.profile()}#basicInfo`,
      matchingRoutePaths: [editBasicInfoRoutePath],
      label: 'Basic info',
    },
    {
      href: `${routes.profile()}#myDocuments`,
      matchingRoutePaths: [
        newResumeRoutePath,
        resumesRoutePath,
        resumesUploadRoutePath,
      ],
      label: 'My Documents',
    },
    {
      href: `${routes.profile()}#industries`,
      matchingRoutePaths: [editIndustriesRoutePath],
      label: 'Industries',
    },
    {
      href: `${routes.profile()}#skills`,
      matchingRoutePaths: [editSkillsRoutePath],
      label: 'Skills',
    },
    {
      href: `${routes.profile()}#certifications`,
      matchingRoutePaths: [
        editCertificationRoutePath,
        newCertificationRoutePath,
      ],
      label: 'Certifications',
    },
    {
      href: `${routes.profile()}#workExperiences`,
      matchingRoutePaths: [
        editWorkExperienceRoutePath,
        newWorkExperienceRoutePath,
      ],
      label: 'Work Experience',
    },
    {
      href: `${routes.profile()}#educations`,
      matchingRoutePaths: [editEducationRoutePath, newEducationRoutePath],
      label: 'Education',
    },
    {
      href: `${routes.profile()}#endorsements`,
      matchingRoutePaths: [newEndorsementRoutePath, endorsementRoutePath],
      label: 'Endorsements',
    },
  ]
  return (
    <Swiper
      className="w-full border-b-2 border-gray-400 md:hidden"
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={16}
      slidesPerView="auto"
      freeMode
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
    >
      {anchors.map((anchor, indx) => {
        const isCurrent =
          fullPath === anchor.href ||
          // This is mainly to ensure that the links stay active when the user adds or edits items.
          // For example, we want the Certifications link to stay active when the user is adding a
          // new certification or editing an existing one.
          anchor.matchingRoutePaths?.includes(currentRoutePath) ||
          // This is to ensure that the Profile link is active when the user navigates to the
          // profile page for the first time.
          (anchor.href === `${routes.profile()}#profile` &&
            fullPath === routes.profile())

        return (
          <SwiperSlide
            className={clsx(
              'grow w-auto ms-4 pb-2',
              indx === anchors.length - 1 ? 'me-4' : '',
              isCurrent
                ? 'text-gray-950 border-b-2 border-primary-700'
                : 'text-gray-900'
            )}
            key={indx}
          >
            <Link href={anchor.href}>
              <Text weight="semibold" className="text-md text-nowrap">
                {anchor.label}
              </Text>
            </Link>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

const AdditionalMobileNavigationLayout = ({ children }) => {
  const screenSize = useScreenSize()
  return (
    <div className="flex h-full w-full max-w-7xl flex-col items-center justify-center gap-1 p-4 pt-0 md:gap-6 md:p-8 md:pt-8">
      {screenSize === 'sm' && (
        <div className="sticky top-14 z-10 pt-4 bg-gray-50 mb-2">
          <MobileNavigation />
        </div>
      )}
      {children}
    </div>
  )
}

export default AdditionalMobileNavigationLayout
