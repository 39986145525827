import React from 'react'

type SVGProps = React.SVGProps<never>

export const SpinnerIcon = (props: SVGProps) => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="currentColor"
    stroke="currentColor"
    {...props}
  >
    <path
      opacity="0.3"
      d="M12.5 6C12.5 9.31371 9.81371 12 6.5 12C3.18629 12 0.5 9.31371 0.5 6C0.5 2.68629 3.18629 0 6.5 0C9.81371 0 12.5 2.68629 12.5 6ZM2 6C2 8.48528 4.01472 10.5 6.5 10.5C8.98528 10.5 11 8.48528 11 6C11 3.51472 8.98528 1.5 6.5 1.5C4.01472 1.5 2 3.51472 2 6Z"
      fill="currentColor"
    />
    <path
      d="M1.25 6C0.835786 6 0.495165 5.6629 0.546806 5.25191C0.69224 4.09448 1.17293 2.99858 1.93756 2.10331C2.86538 1.01698 4.15036 0.297355 5.56139 0.0738699C6.97242 -0.149615 8.41689 0.137708 9.63499 0.884159C10.6389 1.49933 11.4347 2.39305 11.9307 3.44889C12.1068 3.8238 11.887 4.24966 11.493 4.37766C11.0991 4.50566 10.6813 4.28591 10.4898 3.91867C10.1148 3.19998 9.55029 2.5915 8.85124 2.16312C7.93767 1.60328 6.85432 1.38779 5.79604 1.5554C4.73777 1.72302 3.77403 2.26274 3.07817 3.07748C2.54571 3.70092 2.19683 4.45407 2.06235 5.25344C1.99363 5.66191 1.66421 6 1.25 6Z"
      fill="currentColor"
    />
  </svg>
)
