import { useAuth } from 'src/auth'
import { logError } from 'src/lib/utils'
import { API_URL } from 'src/url-config'

export function useFileUpload() {
  const { currentUser } = useAuth()
  return async (filename: string, file: File, fileType: string) => {
    //fetch secure upload link for filename and filetype

    const UPLOAD_FILE_ENDPOINT = `${API_URL}/files?filename=${filename}&filetype=${fileType}&userid=${currentUser.id}`
    const result = await fetch(UPLOAD_FILE_ENDPOINT, {
      headers: {
        'auth-provider': 'dbAuth',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${currentUser.id}`,
      },
      credentials: 'include',
    })

    // make changes below to allow overwrite of profile pictures
    const formData = new FormData()
    formData.append('file', file, filename)

    const resultJson = await result.json()

    // get the sanitized filename and the upload url
    const uploadUrl = resultJson.url
    const sanitizedFilename = resultJson.sanitizedFilename
    const bucket = resultJson.bucket

    try {
      const response = await fetch(uploadUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/octet-stream',
        },
        body: new File([file], sanitizedFilename),
      })

      if (!response.ok) {
        throw new Error('File upload failed')
      }

      // get public url
      const publicUrl = `https://storage.googleapis.com/${bucket}/${sanitizedFilename}`
      return publicUrl
    } catch (error) {
      logError(error)
      throw error
    }
  }
}
