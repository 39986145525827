import { routes } from '@redwoodjs/router'

import { Footer } from 'src/components/ResumeImport/CompleteProfile/steps/Footer'
import { Header } from 'src/components/ResumeImport/CompleteProfile/steps/Header'
import EditSkillsCell from 'src/components/Skill/EditSkillsCell'

export function SkillsStep({
  handleNext,
  initialRecommendedSkillsNames,
  isLastStep,
}: {
  handleNext: () => void
  initialRecommendedSkillsNames: string[]
  isLastStep: boolean
}) {
  return (
    <div className="flex flex-col gap-6">
      <Header title="Did we get your skills right?" />
      <EditSkillsCell
        editSkillsInput={{
          variant: 'custom',
          initialRecommendedSkillsNames,
          recommendedSkillsTitle: 'Suggestions from your resume',
        }}
      />
      <Footer
        handleNext={() => {
          if (isLastStep) {
            // Hard navigate to the profile page to refetch all of the relevant queries and close the modal.
            window.location.replace(routes.profile())
          } else {
            handleNext()
          }
        }}
        isLastStep={isLastStep}
        loading={false}
      />
    </div>
  )
}
