import { useRef, useState } from 'react'

import { DocumentTextIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import { FindPrimaryResumeQuery } from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import { Avatar, Button, Card, Input, PlusIcon, Text } from 'src/atoms'
import { ParseResumeDialog } from 'src/components/ResumeImport/ParseResumeDialog/ParseResumeDialog'
import { FIND_PRIMARY_RESUME_QUERY } from 'src/graphql/queries'
import { useCreateResumeFile } from 'src/hooks/mutations/useCreateResumeFile'
import { useFileUpload } from 'src/hooks/useFiles'
import { useScreenSize } from 'src/hooks/useResize'
import { acceptedFileTypes } from 'src/lib/constants'
import { logError } from 'src/lib/utils'
import { resumeFilesUtils } from 'src/lib/utils/resume-files/resume-files'

export const QUERY = FIND_PRIMARY_RESUME_QUERY

// In a perfect world, we would have a shared component for this. However,
// the current implementation is not reusable enough to justify a shared
// component.
const HeadlessResumeFileUpload = () => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [base64Resume, setBase64Resume] = useState<string | null>(null)

  const uploadFile = useFileUpload()

  const [createResumeFile] = useCreateResumeFile()

  const handleResumeFileUpload = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const file = e.target.files?.[0]
      if (!file) {
        throw new Error('No file selected in headless profile resume upload')
      }

      const uploadUrl = await uploadFile(file.name, file, 'resume')
      if (!uploadUrl) {
        throw new Error('Failed to upload in headless profile resume upload')
      }

      await createResumeFile({
        variables: {
          input: {
            title: file.name,
            url: uploadUrl,
            isLaborupResume: false,
          },
        },
        onCompleted: async () => {
          const base64Resume = await resumeFilesUtils.convertFileToBase64(file)
          if (typeof base64Resume !== 'string') {
            throw new Error(
              'Failed to convert file to base64 string in headless profile resume upload'
            )
          }
          setBase64Resume(base64Resume)
        },
      })
    } catch (e) {
      logError(e)
    }
  }

  const handleShowPicker = () => {
    if (inputRef.current && typeof inputRef.current.showPicker === 'function') {
      inputRef.current.showPicker()
    } else {
      logError(
        new Error('showPicker not available in headless profile resume upload')
      )
    }
  }

  return (
    <>
      {base64Resume ? (
        <ParseResumeDialog base64Resume={base64Resume} />
      ) : (
        <>
          <Button onClick={handleShowPicker}>
            <PlusIcon className="h-5 w-5" /> Upload PDF
          </Button>
          <Input
            accept={acceptedFileTypes}
            className="hidden"
            onChange={handleResumeFileUpload}
            multiple={false}
            ref={inputRef}
            type="file"
          />
        </>
      )}
    </>
  )
}

// Since this appears at the top of a profile, we don't want to show anything while loading.
export const Loading = () => null

export const Empty = ({ className }: { className?: string }) => {
  const screenSize = useScreenSize()

  return (
    <Card
      className={clsx(
        className,
        'flex flex-col items-center gap-4 px-6 py-12 md:py-8 !bg-primary-200'
      )}
    >
      <Avatar className="md:hidden" size="size-8" square>
        <div className="flex flex-col items-center justify-center w-100 h-100">
          <DocumentTextIcon className="w-5 h-5 text-white" />
        </div>
      </Avatar>
      <div className="flex flex-col gap-[6px] text-center">
        <Text weight="bold" className="text-2xl">
          {screenSize === 'sm'
            ? 'Upload your resume to complete your profile'
            : 'Upload your resume'}
        </Text>
        <Text className="text-sm text-gray-900">
          {screenSize === 'sm'
            ? 'Stand out by completing your profile—import details from your resume for a quick setup.'
            : 'Quickly autofill different sections and complete your profile'}
        </Text>
      </div>
      <HeadlessResumeFileUpload />
    </Card>
  )
}

export const Failure = ({ error }: CellFailureProps) => (
  <div className="rw-cell-error">{error?.message}</div>
)

export const Success = ({
  primaryResumeFile: _primaryResumeFile,
}: CellSuccessProps<FindPrimaryResumeQuery> & { className?: string }) => {
  // If we are here it means the user has a resume. Don't show anything.
  return null
}
