import { useRef } from 'react'
import { useCallback } from 'react'

import { FindSkills } from 'types/graphql'
import { EditSkillsInput } from 'types/index'

import { navigate, routes } from '@redwoodjs/router'

import {
  BadgeButton,
  Button,
  Field,
  FieldGroup,
  Fieldset,
  Input,
  PlusIcon,
  XMarkIcon,
} from 'src/atoms'
import { RecommendedSkills } from 'src/components/RecommendedSkills/RecommendedSkills'
import RecommendedSkillsCell from 'src/components/RecommendedSkillsCell'
import { SkillSchema } from 'src/schemas'
import { ElementTypeFromArray } from 'src/types'

type Skill = ElementTypeFromArray<FindSkills['skills']>

type Props = {
  skills: Skill[]
  loading: boolean
  editSkillsInput: EditSkillsInput
  onSubmit: (input: SkillSchema) => void
  onDelete: (id: number) => void
  showSaveButton?: boolean
}

const SkillForm = ({
  skills,
  loading,
  editSkillsInput,
  onSubmit,
  onDelete,
  showSaveButton,
}: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const existingSkillsNames = skills
    .map((skill) => skill.name)
    .filter((name) => typeof name === 'string')

  const handleDelete = useCallback(
    (skill: Skill) => {
      if (!skill) return
      onDelete(skill.id)
    },
    [onDelete]
  )

  const handleAddSkill = useCallback(
    (name?: string) => {
      if (!name) {
        return
      }
      const formattedName = name.trim()
      const isExistingSkill = skills.find(
        (skill) => formattedName === skill.name
      )
      if (formattedName && !isExistingSkill) {
        onSubmit({ name: formattedName })
      }
      if (inputRef.current) {
        inputRef.current.value = ''
      }
    },
    [onSubmit, skills]
  )

  const handleClickAddButton = useCallback(() => {
    handleAddSkill(inputRef.current?.value)
  }, [handleAddSkill])

  const handleClickSaveButton = useCallback(() => {
    handleClickAddButton()
    navigate(routes.profile())
  }, [handleClickAddButton])

  const handleKeyDownInput: React.KeyboardEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        if (e.key !== 'Enter') {
          return
        }
        e.preventDefault()
        handleClickAddButton()
      },
      [handleClickAddButton]
    )

  return (
    <form>
      <FieldGroup className="flex flex-col gap-2">
        <Fieldset>
          <Field className="mb-4 flex flex-row flex-nowrap items-end">
            <div className="me-2 flex flex-1 flex-col">
              <Input
                ref={inputRef}
                placeholder="Tools, machines, and skills"
                onKeyDown={handleKeyDownInput}
              />
            </div>
            <Button disabled={loading} size="lg" onClick={handleClickAddButton}>
              <PlusIcon />
              Add
            </Button>
          </Field>
          <div className="d-flex mt-n2 ms-n2 flex-wrap">
            {skills.map((skill, indx) => (
              <BadgeButton
                key={indx}
                className="fs-sm mb-1 me-1 ms-2 mt-2"
                onClick={() => {
                  handleDelete(skill)
                }}
              >
                {skill.name}
                <XMarkIcon className="h-4 w-4 text-gray-950" />
              </BadgeButton>
            ))}
          </div>
        </Fieldset>
        {editSkillsInput.variant === 'custom' ? (
          <RecommendedSkills
            handleAddNewSkill={handleAddSkill}
            loading={loading}
            existingSkillsNames={existingSkillsNames}
            initialRecommendedSkillsNames={
              editSkillsInput.initialRecommendedSkillsNames
            }
            recommendedSkillsTitle={editSkillsInput.recommendedSkillsTitle}
          />
        ) : (
          <RecommendedSkillsCell
            existingSkillsNames={existingSkillsNames}
            handleAddNewSkill={handleAddSkill}
            loading={loading}
            // ? Why isn't TS catching this as required?
            editSkillsInput={editSkillsInput}
          />
        )}
        {showSaveButton && (
          <div className="w-100 text-color-950 flex flex-col">
            <Button onClick={handleClickSaveButton}>Save</Button>
          </div>
        )}
      </FieldGroup>
    </form>
  )
}

export default SkillForm
