import clsx from 'clsx'

import { routes } from '@redwoodjs/router'

import {
  Card,
  Link,
  ProgressBar,
  ProgressBarColor,
  ProgressBarSize,
  Text,
} from 'src/atoms'

type ProfileProgress = {
  overallProgress: number
  industries: boolean
  workExperience: boolean
  education: boolean
  skills: boolean
  certifications: boolean
  documents: boolean
  endorsements: boolean
}

type ProfileQuality = {
  title: string
  progressBarColor: ProgressBarColor
}

type ProfileTip = {
  description: string
  hrefText: string
  href: string
}

const computeProfileQuality = (overallProgress: number): ProfileQuality => {
  if (overallProgress >= 90) {
    return {
      title: 'Strong',
      progressBarColor: ProgressBarColor.green,
    }
  } else if (overallProgress >= 50) {
    return {
      title: 'Moderate',
      progressBarColor: ProgressBarColor.yellow,
    }
  }
  return {
    title: 'Weak',
    progressBarColor: ProgressBarColor.red,
  }
}

const computeProfileTip = (
  profileProgress: ProfileProgress
): ProfileTip | undefined => {
  if (!profileProgress.workExperience) {
    return {
      description: 'Add your current and past work experience',
      hrefText: 'Add work experience',
      href: routes.newWorkExperience(),
    }
  } else if (!profileProgress.education) {
    return {
      description: 'Add your education or school background',
      hrefText: 'Add education',
      href: routes.newEducation(),
    }
  } else if (!profileProgress.industries) {
    return {
      description: 'Add any industries you have worked in',
      hrefText: 'Add industries',
      href: routes.editIndustries(),
    }
  } else if (!profileProgress.skills) {
    return {
      description: 'Add any skills that you have',
      hrefText: 'Add skills',
      href: routes.editSkills(),
    }
  } else if (!profileProgress.documents) {
    return {
      description: 'Add your resume or create a Laborup resume',
      hrefText: 'Add resume',
      href: routes.resumes(),
    }
  } else if (!profileProgress.certifications) {
    return {
      description: 'Add any certifications you have received',
      hrefText: 'Add certification',
      href: routes.newCertification(),
    }
  } else if (!profileProgress.endorsements) {
    return {
      description:
        "Get endorsements from coworkers you've worked with at a factory or manufacturing company",
      hrefText: 'Get endorsement',
      href: routes.newEndorsement(),
    }
  }
}

export const QUERY = gql`
  query getProfileProgress {
    profileProgress: getProfileProgress {
      overallProgress
      industries
      workExperience
      education
      skills
      certifications
      documents
      endorsements
    }
  }
`

export const Loading = () => <></>

export const Empty = () => <div>Empty</div>

export const beforeQuery = (props) => {
  return { variables: props }
}

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export const Success = ({
  profileProgress,
  className,
}: {
  profileProgress: ProfileProgress
  className?: string
}) => {
  const profileQuality: ProfileQuality = computeProfileQuality(
    profileProgress.overallProgress
  )

  const profileTip: ProfileTip | undefined = computeProfileTip(profileProgress)

  return (
    <Card className={clsx(className, 'flex flex-col gap-4')}>
      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-between">
          <Text weight="semibold" className="text-md text-gray-900">
            Profile Quality:{' '}
            <span className="text-gray-950">{profileQuality.title}</span>
          </Text>
          <Text
            weight="semibold"
            className="text-md text-gray-900"
          >{`${profileProgress.overallProgress}%`}</Text>
        </div>
        <ProgressBar
          color={profileQuality.progressBarColor}
          progress={profileProgress.overallProgress}
          size={ProgressBarSize.sm}
        />
      </div>
      {profileTip && (
        <Card className="flex flex-col gap-2 !px-4 !py-3 border-blackAlpha-50 !bg-primary-200">
          <Text className="text-sm">
            <span className="font-semibold">Tip:</span> {profileTip.description}
          </Text>
          <Link
            className="text-sm font-semibold text-primary-800 "
            href={profileTip.href}
          >
            {profileTip.hrefText}
          </Link>
        </Card>
      )}
    </Card>
  )
}
