import { clsx } from 'clsx'

import { Link } from '../Link/Link'

type WeightType = 'normal' | 'medium' | 'semibold' | 'bold'

const weightMap = {
  normal: 'font-normal',
  medium: 'font-medium',
  semibold: 'font-semibold',
  bold: 'font-bold',
}

export function Text({
  className,
  weight = 'normal',
  ...props
}: { weight?: WeightType } & React.ComponentPropsWithoutRef<'p'>) {
  return (
    <p
      {...props}
      data-slot="text"
      className={clsx(className, weightMap[weight])}
    />
  )
}

export function TextLink({
  className,
  weight = 'normal',
  ...props
}: { weight: WeightType } & React.ComponentPropsWithoutRef<typeof Link>) {
  return <Link {...props} className={clsx(className, weightMap[weight])} />
}

export function Strong({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'strong'>) {
  return <strong {...props} className={clsx(className, 'font-medium')} />
}

export function Code({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'code'>) {
  return (
    <code
      {...props}
      className={clsx(
        className,
        'rounded border px-0.5 text-sm font-medium sm:text-[0.8125rem]'
      )}
    />
  )
}
