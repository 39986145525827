import { Text } from 'src/atoms'

export default function SalaryPrefix() {
  return (
    <div>
      <Text
        weight="medium"
        className="flex px-2 text-gray-950 h-full items-center text-md rounded-tl-lg rounded-bl-lg border-r border-gray-500 text-nowrap whitespace-nowrap"
      >
        $
      </Text>
    </div>
  )
}
