import { ArrowRightIcon } from '@heroicons/react/24/solid'

import { Button, Text } from 'src/atoms'
import { intercomUtils } from 'src/lib/utils/intercom/intercom'

export default function ErrorContent({
  tag,
  title,
  description,
  imageUrl,
  secondaryButtonText,
  secondaryButtonOnClick,
}: {
  tag: string
  title: string
  description: string
  imageUrl: string
  secondaryButtonText: string
  secondaryButtonOnClick: () => void
}) {
  const handleOpenIntercom = () => {
    intercomUtils.openIntercom()
  }

  return (
    <div className="flex items-center justify-center p-12 gap-24">
      <div className="flex flex-col gap-12">
        <div className="flex flex-col gap-4">
          <Text weight="bold" className="text-md text-primary-700">
            {tag}
          </Text>
          <Text weight="bold" className="text-4xl text-gray-950 md:text-6xl">
            {title}
          </Text>
          <Text weight="medium" className="text-lg text-gray-900 md:text-xl">
            {description}
          </Text>
        </div>
        <div className="flex items-center gap-3">
          <Button onClick={secondaryButtonOnClick} outline>
            {secondaryButtonText}
          </Button>
          {/*
           * We have to use `window.location.replace` instead of `navigate` since this
           * component may be used outside of the context of the `LocationProvider`.
           */}
          <Button onClick={() => window.location.replace('/')}>Go home</Button>
        </div>
        <div className="flex flex-col gap-6">
          <div>
            {/*
             * We have to use `<a>` instead of `<Link>` since this component
             * may be used outside of the context of the `LocationProvider`.
             */}
            <a
              href="/"
              className="flex items-center gap-2 text-md text-primary-800 font-semibold"
            >
              Home <ArrowRightIcon className="h-4 w-4" />
            </a>
            <Text className="text-md text-gray-900">
              View your job recommendations and matches.
            </Text>
          </div>
          <div>
            {/*
             * We have to use `<a>` instead of `<Link>` since this component
             * may be used outside of the context of the `LocationProvider`.
             */}
            <a
              href="/jobs/search"
              className="flex items-center gap-2 text-md text-primary-800 font-semibold"
            >
              Find Jobs <ArrowRightIcon className="h-4 w-4" />
            </a>
            <Text className="text-md text-gray-900">
              Discover opportunities at leading manufacturing companies.
            </Text>
          </div>
          <div>
            {/* NOTE: We have to use `<a>` instead of `<Link>` for mailto. */}
            <a
              href="mailto:support@laborup.com"
              className="flex items-center gap-2 text-md text-primary-800 font-semibold"
            >
              Contact Us <ArrowRightIcon className="h-4 w-4" />
            </a>
            <Text className="text-md text-gray-900">
              If you need any help, feel free to message us{' '}
              <button
                className="text-md text-gray-900 underline underline-offset-2"
                onClick={handleOpenIntercom}
              >
                here
              </button>
              !
            </Text>
          </div>
        </div>
      </div>
      <img
        className="hidden rounded-lg xl:block"
        src={imageUrl}
        width="530"
        alt={imageUrl}
      />
    </div>
  )
}
