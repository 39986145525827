import { Text } from 'src/atoms'

const companies = [
  {
    name: 'Toyota',
    src: '/images/company-logos/toyota_logo_white.svg',
  },
  {
    name: 'Ford',
    src: '/images/company-logos/ford_logo_white.svg',
  },
  {
    name: 'GM',
    src: '/images/company-logos/gm_logo_white.svg',
  },
  {
    name: 'Lockeed Martin',
    src: '/images/company-logos/lockheed_logo_wite.svg',
  },
  {
    name: 'Smith and Wesson',
    src: '/images/company-logos/smith_and_wessons_white.svg',
  },
  {
    name: 'Nissan',
    src: '/images/company-logos/nissan_logo_white.svg',
  },
  { name: 'SpaceX', src: '/images/logo/spacex_logo_white.svg' },
]

const CompaniesBanner = () => {
  return (
    <div className="flex items-center flex-col gap-7 w-1/2">
      <Text weight="semibold" className="text-xl text-white text-center">
        Find jobs <strong>10x faster</strong>, that pay up to{' '}
        <strong>$60/hour</strong>
      </Text>
      <div className="flex flex-wrap justify-center items-center">
        {companies.map((company, indx) => (
          <div
            key={indx}
            className="w-1/2 px-4 py-2 mb-4 flex grow justify-center"
          >
            <img className="h-auto w-30" src={company.src} alt="company logo" />
          </div>
        ))}
      </div>
    </div>
  )
}

export default CompaniesBanner
