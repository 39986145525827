import { useCallback, useEffect, useState } from 'react'

import { Dialog, DisjointProgressBar } from 'src/atoms'
import { mixpanelUtils } from 'src/lib/utils/mixpanel/mixpanel'

import { DesiredPositionStep } from './DesiredPositionStep/DesiredPositionStep'
import EmploymentStatusStepCell from './EmploymentStatusStepCell/'
import { IndustriesStep } from './IndustriesStep/IndustriesStep'
import { SkillsStep } from './SkillsStep/SkillsStep'

type State =
  | {
      currentStepIndex: 0
    }
  | {
      currentStepIndex: 1
    }
  | {
      currentStepIndex: 2
      desiredPosition: string
      isTopStartingRole: boolean
    }
  | {
      currentStepIndex: 3
      desiredPosition: string
      isTopStartingRole: boolean
    }

export const defaultOnboardingDialogTotalSteps = 4

const dialogScrollableId = 'default-onboarding-dialog'

export function DefaultOnboardingDialog() {
  const [state, setState] = useState<State>({
    currentStepIndex: 0,
  })

  const scrollToTop = useCallback(() => {
    const dialog = document.getElementById(dialogScrollableId)
    if (dialog) {
      dialog.scrollTop = 0
    }
  }, [])

  const trackStepEvent = useCallback((step: number) => {
    if (sessionStorage.getItem(`onboarding_step${step}`)) {
      mixpanelUtils.trackUserRevisitingOnboardingStep(step)
    } else {
      sessionStorage.setItem(`onboarding_step${step}`, 'true')
      mixpanelUtils.trackOnboardingStep(step)
    }
  }, [])

  // Send Mixpanel event for step 0 when the component initially renders.
  useEffect(() => {
    trackStepEvent(0)
  }, [trackStepEvent])

  const handleSetState = (newState: State) => {
    // Send Mixpanel event for the step the user is going to.
    trackStepEvent(newState.currentStepIndex)

    setState(newState)
  }

  return (
    <Dialog
      className="p-6"
      onClose={() => {}} // This prevents the dialog from being manually closed.
      scrollableId={dialogScrollableId}
      size="fullScreen"
      open
    >
      <div className="flex flex-col gap-4">
        <DisjointProgressBar
          currentStep={state.currentStepIndex}
          totalSteps={defaultOnboardingDialogTotalSteps}
        />
        {state.currentStepIndex === 0 && (
          <EmploymentStatusStepCell
            handleNext={() => {
              handleSetState({
                currentStepIndex: 1,
              })
            }}
          />
        )}
        {state.currentStepIndex === 1 && (
          <DesiredPositionStep
            handleNextAndSetDesiredPosition={({
              desiredPosition,
              isTopStartingRole,
            }: {
              desiredPosition: string
              isTopStartingRole: boolean
            }) => {
              handleSetState({
                ...state,
                currentStepIndex: 2,
                desiredPosition,
                isTopStartingRole,
              })
            }}
            scrollToTop={scrollToTop}
          />
        )}
        {state.currentStepIndex === 2 && (
          <SkillsStep
            desiredPosition={state.desiredPosition}
            shouldOnlyRecommendSoftSkills={state.isTopStartingRole}
            handleBack={() => {
              handleSetState({
                ...state,
                currentStepIndex: 1,
              })
            }}
            handleNext={() => {
              handleSetState({
                ...state,
                currentStepIndex: 3,
              })
            }}
            scrollToTop={scrollToTop}
          />
        )}
        {state.currentStepIndex === 3 && (
          <IndustriesStep
            isTopStartingRole={state.isTopStartingRole}
            handleBack={() => {
              handleSetState({
                ...state,
                currentStepIndex: 2,
              })
            }}
            scrollToTop={scrollToTop}
          />
        )}
      </div>
    </Dialog>
  )
}
