import { useCallback } from 'react'

import {
  FindIndustries,
  FindIndustriesVariables,
  CreateIndustryMutation,
  CreateIndustryMutationVariables,
  DeleteIndustryMutation,
  DeleteCertificationMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import type {
  CellSuccessProps,
  CellFailureProps,
  TypedDocumentNode,
} from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { Text } from 'src/atoms'
import { defaultRecommendedIndustryNames } from 'src/lib/constants'
import { IndustrySchema } from 'src/schemas'

import IndustryForm from '../IndustryForm/IndustryForm'

export const QUERY: TypedDocumentNode<FindIndustries, FindIndustriesVariables> =
  gql`
    query FindIndustries {
      industries {
        id
        name
        createdAt
        updatedAt
        userId
      }
    }
  `

const DELETE_INDUSTRY_MUTATION: TypedDocumentNode<
  DeleteIndustryMutation,
  DeleteCertificationMutationVariables
> = gql`
  mutation DeleteIndustryMutation($id: Int!) {
    deleteIndustry(id: $id) {
      id
    }
  }
`

const CREATE_INDUSTRY_MUTATION: TypedDocumentNode<
  CreateIndustryMutation,
  CreateIndustryMutationVariables
> = gql`
  mutation CreateIndustryMutation($input: CreateIndustryInput!) {
    createIndustry(input: $input) {
      id
    }
  }
`

type IntrinsicProps = {
  showSaveButton?: boolean
  initialRecommendedIndustryNames?: string[]
  recommendedIndustriesTitle?: string
}

export const Loading = () => (
  <Text weight="semibold" className="text-sm text-gray-700">
    Loading...
  </Text>
)

export const Failure = ({ error }: CellFailureProps) => (
  <>{error && <div className="rw-cell-error">{error.message}</div>}</>
)

export const Success = ({
  industries,
  initialRecommendedIndustryNames = defaultRecommendedIndustryNames,
  recommendedIndustriesTitle = 'Laborup Suggestions',
  showSaveButton,
}: CellSuccessProps<FindIndustries, FindIndustriesVariables> &
  IntrinsicProps) => {
  const [createIndustry, { loading: createIndustryLoading }] = useMutation(
    CREATE_INDUSTRY_MUTATION,
    {
      // * We purposely don't show a success toast here because industries can be added
      // * in rapid succession from the suggestions and causes the toasts to take up
      // * a large amount of the screen.

      onError: (error) => {
        toast.error(error.message)
      },
      refetchQueries: [{ query: QUERY }],
      awaitRefetchQueries: true,
    }
  )

  const [deleteIndustry, { loading: deleteIndustryLoading }] = useMutation(
    DELETE_INDUSTRY_MUTATION,
    {
      onCompleted: () => {
        toast.success('Industry deleted')
      },
      onError: (error) => {
        toast.error(error.message)
      },
      refetchQueries: [{ query: QUERY }],
      awaitRefetchQueries: true,
    }
  )

  const handleSubmit = useCallback(
    (input: IndustrySchema) => {
      createIndustry({ variables: { input } })
    },
    [createIndustry]
  )

  const handleDelete = useCallback(
    (id: number) => {
      deleteIndustry({ variables: { id } })
    },
    [deleteIndustry]
  )

  return (
    <IndustryForm
      industries={industries}
      onSubmit={handleSubmit}
      onDelete={handleDelete}
      showSaveButton={showSaveButton}
      loading={createIndustryLoading || deleteIndustryLoading}
      initialRecommendedIndustryNames={initialRecommendedIndustryNames}
      recommendedIndustriesTitle={recommendedIndustriesTitle}
    />
  )
}
