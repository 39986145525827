import { useEffect } from 'react'

import mixpanel from 'mixpanel-browser'

import { useLocation, useRoutePath } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import { logError } from 'src/lib/utils'

export default function GoogleAnalyticsPageViewLayout({
  children,
}: {
  children: React.ReactNode
}) {
  const { currentUser } = useAuth()
  const { pathname } = useLocation()
  const accessRoutePath = useRoutePath('access')
  const currentRoutePath = useRoutePath()

  useEffect(() => {
    // If the access route path or the current route path is not defined yet, don't attempt to inject the Meta pixel.
    if (!accessRoutePath || !currentRoutePath) {
      return
    }

    // If the Meta pixel has already been injected or the user is on the access route, don't inject the Meta pixel.
    // @ts-expect-error: `fbq` will be defined on the window object after the Meta pixel script has been injected.
    if (window.fbq || accessRoutePath === currentRoutePath) {
      return
    }

    const script = document.createElement('script')
    script.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '531354059836124');
      fbq('track', 'PageView');
    `
    document.head.appendChild(script)
  }, [accessRoutePath, currentRoutePath])

  useEffect(() => {
    // this is running multiple times, needs a fix
    try {
      const trackData: {
        page: string
        distinct_id?: number
      } = { page: pathname, distinct_id: currentUser?.id }
      mixpanel.track('Page View', trackData)
    } catch (error) {
      logError(error)
    }
  }, [pathname, currentUser])

  useEffect(() => {
    // @ts-expect-error: (Gor) Didn't spend time on fixing this
    if (window && window?.gtag) {
      // @ts-expect-error: (Gor) Didn't spend time on fixing this
      window?.gtag('event', 'page_view', {
        page_location: pathname,
      })
    }
  }, [pathname, currentUser])

  return <>{children}</>
}
