export const resumeFilesUtils = {
  convertFileToBase64: (file: File) => {
    return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  },

  isPdf: (file: File) => {
    return (
      file.name.toLowerCase().endsWith('.pdf') &&
      file.type === 'application/pdf'
    )
  },
}
