// This page will be rendered when an error makes it all the way to the top of
// the application without being handled by a Javascript catch statement or
// React error boundary.
// You can modify this page as you wish, but it is important to keep things
// simple to avoid the possibility that it will cause its own error. If it
// does, Redwood will still render a generic error page, but your users will
// prefer something a bit more thoughtful :)

import { FallbackRender } from '@sentry/react'

// This import will be automatically removed when building for production
import { DevFatalErrorPage } from '@redwoodjs/web/dist/components/DevFatalErrorPage'

import ErrorContent from 'src/components/ErrorContent/ErrorContent'

const ProductionFatalErrorPage: FallbackRender = ({ resetError }) => {
  return (
    <main>
      <section className="min-h-screen flex items-center justify-center">
        <ErrorContent
          tag="Error"
          title="Something went wrong"
          description="We encountered an error. Try refreshing or heading back to home."
          imageUrl="/images/male_manufacturing_worker_wires.png"
          secondaryButtonText="Refresh"
          secondaryButtonOnClick={() => {
            resetError()
            window.location.reload()
          }}
        />
      </section>
    </main>
  )
}

// @ts-expect-error: (Kohli) didn't spend time on this
const FatalErrorPage: FallbackRender =
  process.env.NODE_ENV === 'development'
    ? DevFatalErrorPage
    : ProductionFatalErrorPage

export default FatalErrorPage
