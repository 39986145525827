import React from 'react'

import {
  FieldValues,
  useFormContext,
  Path,
  Controller,
  PathValue,
} from 'react-hook-form'

import { Textarea, TextareaProps, ErrorMessage } from 'src/atoms'

export type RHFTextareaProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>
  description?: React.ReactNode
  clearErrorOnChange?: boolean
} & Omit<TextareaProps, 'value'>

export const RHFTextarea = <TFieldValues extends FieldValues>({
  name,
  description,
  onChange: handleChangeProps,
  clearErrorOnChange,
  ...props
}: RHFTextareaProps<TFieldValues>) => {
  const { control, clearErrors, setValue } = useFormContext<TFieldValues>()

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange: handleChangeField, ...field },
        fieldState: { error },
      }) => {
        const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (
          e
        ) => {
          if (clearErrorOnChange) {
            clearErrors(name)
            setValue(
              name,
              e.target.value as PathValue<TFieldValues, Path<TFieldValues>>
            )
          } else {
            handleChangeField(e)
          }
          handleChangeProps?.(e)
        }
        return (
          <>
            <Textarea onChange={handleChange} {...field} {...props} />
            {description}
            <ErrorMessage className="ms-1.5" message={error?.message} />
          </>
        )
      }}
    />
  )
}
