import { useEffect } from 'react'

import { navigate, routes } from '@redwoodjs/router'

import { Button, Text } from 'src/atoms'
import { DefaultOnboardingDialogStickyFooterContainer } from 'src/components/InAppOnboardingDialogCell/DefaultOnboardingDialog/DefaultOnboardingDialogStickyFooterContainer/DefaultOnboardingDialogStickyFooterContainer'
import EditIndustriesCell from 'src/components/Industry/EditIndustriesCell'

export function IndustriesStep({
  isTopStartingRole,
  handleBack,
  scrollToTop,
}: {
  isTopStartingRole: boolean
  handleBack: () => void
  scrollToTop: () => void
}) {
  useEffect(() => {
    // When we arrive at this step make sure we scroll to the top of the page.
    scrollToTop()
  }, [scrollToTop])

  const handleComplete = () => {
    window.location.reload()
    navigate(routes.profile())
  }

  return (
    <div className="flex flex-col gap-6">
      <div>
        <Text weight="bold" className="text-2xl">
          {isTopStartingRole
            ? 'Have you worked in any specific industries?'
            : 'What industries have you worked in?'}
        </Text>
        <Text className="text-md text-gray-900">
          You can change this anytime in your profile.
        </Text>
      </div>
      <EditIndustriesCell />
      {isTopStartingRole && (
        <Text weight="medium" className="text-sm">
          Have no prior experience?{' '}
          {/* Use plain button so it can be properly inlined. */}
          <button className="font-semibold underline" onClick={handleComplete}>
            Skip this question
          </button>
        </Text>
      )}
      <DefaultOnboardingDialogStickyFooterContainer>
        <Button className="w-full" color="white" onClick={handleBack}>
          Back
        </Button>
        <Button className="w-full" onClick={handleComplete}>
          Next
        </Button>
      </DefaultOnboardingDialogStickyFooterContainer>
    </div>
  )
}
