import { useCallback, useMemo, useState } from 'react'

import { ChevronDownIcon } from '@heroicons/react/24/solid'
import type { FindAvatarFile, FindAvatarFileVariables } from 'types/graphql'

import { navigate } from '@redwoodjs/router'
import { routes } from '@redwoodjs/router'
import {
  useMutation,
  type CellSuccessProps,
  type CellFailureProps,
  TypedDocumentNode,
} from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { Avatar, Button, Text } from 'src/atoms'
import DeleteDialog from 'src/components/DeleteDialog/DeleteDialog'
import { UPDATE_AVATAR_FILE_MUTATION } from 'src/components/Profile/EditProfileCell'

export const QUERY: TypedDocumentNode<FindAvatarFile, FindAvatarFileVariables> =
  gql`
    query FindAvatarFile {
      avatarFile: avatarFile {
        id
        url
        createdAt
        updatedAt
        userId
      }
      profile: profile {
        firstName
        lastName
      }
    }
  `

type IntrinsicProps = {
  className?: string
  size: string
  withDropdownIcon?: boolean
  isNavbar?: boolean
}

type SuccessProps = CellSuccessProps<FindAvatarFile> & IntrinsicProps
type FailureProps = CellFailureProps & IntrinsicProps

export const Loading = () => {
  return (
    <Text weight="semibold" className="text-sm text-gray-700">
      Loading...
    </Text>
  )
}

export const Empty = ({ size }: IntrinsicProps) => {
  return <Avatar size={size} />
}

export const Failure = ({ error }: FailureProps) => (
  <>{error ? <div className="rw-cell-error">{error.message}</div> : null}</>
)

const getInitials = (name: string) =>
  name
    .split(' ')
    .filter((item) => !!item)
    .reduce((acc, item) => acc + item[0], '')
    .toUpperCase()

export const Success = ({
  avatarFile,
  profile,
  className = '',
  size,
  withDropdownIcon = false,
  isNavbar = true,
}: SuccessProps) => {
  const [open, setOpen] = useState(false)
  const [updateAvatarFile] = useMutation(UPDATE_AVATAR_FILE_MUTATION, {
    onError: (error) => {
      toast.error(error.message)
    },
    onCompleted: () => {
      toast.success('Profile picture removed')
    },
    refetchQueries: [{ query: QUERY }],
    awaitRefetchQueries: true,
  })

  const handleSubmitDelete = useCallback(() => {
    updateAvatarFile({ variables: { input: { url: '' } } })
    setOpen(false)
  }, [updateAvatarFile])

  const initials = useMemo(
    () => getInitials(`${profile?.firstName || ''} ${profile?.lastName || ''}`),
    [profile?.firstName, profile?.lastName]
  )

  return (
    <>
      {!isNavbar ? (
        <div className="flex items-center gap-8">
          <div className="position-relative flex-shrink-0">
            {avatarFile && (
              <Avatar
                size={size}
                initials={initials}
                className={className}
                square
                src={avatarFile.url}
                alt="Avatar"
              />
            )}
          </div>
          <div className="flex gap-8 flex-wrap">
            <Button
              onClick={() => navigate(routes.editAvatar())}
              outline
              size="lg"
            >
              {avatarFile && avatarFile.id && avatarFile.url
                ? 'Change picture'
                : 'Add picture'}
            </Button>
            {avatarFile && avatarFile.id && avatarFile.url && (
              <Button
                onClick={() => {
                  setOpen(true)
                }}
                plain
                size="lg"
              >
                Remove
              </Button>
            )}
          </div>
          {open && avatarFile && (
            <DeleteDialog
              open={open}
              id={avatarFile.id}
              onSubmit={handleSubmitDelete}
              onCancel={() => setOpen(false)}
            />
          )}
        </div>
      ) : (
        <div className="flex items-center gap-2">
          <div className="position-relative flex-shrink-0">
            {avatarFile && (
              <Avatar
                size={size}
                initials={initials}
                className={className}
                square
                src={avatarFile.url}
                alt="Avatar"
              />
            )}
          </div>
          {withDropdownIcon && (
            <ChevronDownIcon className="h-4 w-4 text-gray-500" />
          )}
        </div>
      )}
    </>
  )
}
