export function isValidDate(d: unknown): d is Date {
  return d instanceof Date && !isNaN(d.getTime())
}

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
] as const

export type Month = (typeof months)[number]

export const monthIndex: Record<Month, number> = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
} as const

export function createDate(month: Month, year: number) {
  const index = monthIndex[month]
  return new Date(year, index, 1)
}

export function logError(error: Error) {
  console.log('%c Handled Error:', 'color: #bada55')
  console.error(error.message)
}
