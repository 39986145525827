export enum ProgressBarColor {
  primary = 'primary',
  green = 'green',
  yellow = 'yellow',
  red = 'red',
}

export enum ProgressBarSize {
  xs = 'xs',
  sm = 'sm',
}

type Variants = {
  color?: ProgressBarColor
  size?: ProgressBarSize
}

type DisjointProgressBarCustomProps = {
  currentStep: number
  totalSteps: number
}

export type DisjointProgressBarProps = React.HTMLAttributes<HTMLDivElement> &
  DisjointProgressBarCustomProps &
  Variants

type ProgressBarCustomProps = {
  progress: number
}

export type ProgressBarProps = React.HTMLAttributes<HTMLDivElement> &
  ProgressBarCustomProps &
  Variants
