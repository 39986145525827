/**
 * A variation of the `<ProgressBar>` component in which progress is
 * represented as `n` equal-width bars as opposed to a single bar.
 */

import React from 'react'

import { cva } from 'class-variance-authority'
import clsx from 'clsx'

import {
  DisjointProgressBarProps,
  ProgressBarColor,
  ProgressBarSize,
} from './types'

const disjointProgressBarOuterVariants = cva('w-full', {
  variants: {
    color: {
      [ProgressBarColor.primary]: 'bg-gray-400',
      [ProgressBarColor.green]: 'bg-green-950',
      [ProgressBarColor.yellow]: 'bg-yellow-950',
      [ProgressBarColor.red]: 'bg-red-950',
    },
    size: {
      [ProgressBarSize.xs]: 'h-1',
      [ProgressBarSize.sm]: 'h-2',
    },
  },
  defaultVariants: {
    color: ProgressBarColor.primary,
    size: ProgressBarSize.xs,
  },
})

const disjointProgressBarInnerVariants = cva('h-full', {
  variants: {
    color: {
      [ProgressBarColor.primary]: 'bg-primary-700',
      [ProgressBarColor.green]: 'bg-green-800',
      [ProgressBarColor.yellow]: 'bg-yellow-800',
      [ProgressBarColor.red]: 'bg-red-800',
    },
  },
  defaultVariants: {
    color: ProgressBarColor.primary,
  },
})

export const DisjointProgressBar = React.forwardRef(
  function DisjointProgressBar(
    {
      color,
      currentStep,
      size,
      totalSteps,
      className,
      ...props
    }: DisjointProgressBarProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) {
    return (
      <div
        ref={ref}
        className={clsx('w-full flex gap-2', className)}
        {...props}
      >
        {Array.from({ length: totalSteps }).map((_, index) => {
          return (
            <div
              className={disjointProgressBarOuterVariants({ color, size })}
              key={index}
            >
              <div
                style={{
                  transition: 'width 512ms ease-out',
                  width: currentStep < index ? '0' : '100%',
                }}
                className={disjointProgressBarInnerVariants({ color })}
              ></div>
            </div>
          )
        })}
      </div>
    )
  }
)
