const KEY_IS_NEW_ACCESS_PROFILE_SIGNUP = 'isNewAccessProfileSignup'

export const sessionStorageUtils = {
  getIsNewAccessProfileSignup: () => {
    return sessionStorage.getItem(KEY_IS_NEW_ACCESS_PROFILE_SIGNUP)
  },
  setIsNewAccessProfileSignup: () => {
    sessionStorage.setItem(KEY_IS_NEW_ACCESS_PROFILE_SIGNUP, 'true')
  },
  removeIsNewAccessProfileSignup: () => {
    sessionStorage.removeItem(KEY_IS_NEW_ACCESS_PROFILE_SIGNUP)
  },
}
