import React from 'react'

import { monthOptions } from 'src/lib/constants'

import { Select, SelectProps } from './Select'

export type SelectMonthProps = SelectProps

export const SelectMonth = React.forwardRef<
  HTMLSelectElement,
  SelectMonthProps
>(function SelectMonth(props, ref) {
  return (
    <Select ref={ref} {...props}>
      {monthOptions.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  )
})
