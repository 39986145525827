import { forwardRef } from 'react'

import {
  Textarea as HeadlessTextarea,
  type TextareaProps as HeadlessTextareaProps,
} from '@headlessui/react'
import { clsx } from 'clsx'

export type TextareaProps = {
  resizable?: boolean
  textAreaClassNames?: string
} & HeadlessTextareaProps

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  function Textarea(
    { className, textAreaClassNames, resizable = true, ...props },
    ref
  ) {
    return (
      <span
        data-slot="control"
        className={clsx([
          className,

          // Basic layout
          'relative block w-full',

          // Background color + shadow applied to inset pseudo element, so shadow blends with border in light mode
          'before:shadow before:absolute before:inset-px before:rounded-[calc(theme(borderRadius.lg)-1px)] before:bg-white',
          'after:pointer-events-none after:absolute after:inset-0 after:rounded-lg after:ring-inset after:ring-transparent sm:after:focus-within:ring-2 sm:after:focus-within:ring-primary-700',
          'has-[[data-disabled]]:opacity-50 before:has-[[data-disabled]]:bg-gray-50 before:has-[[data-disabled]]:shadow-none',
        ])}
      >
        <HeadlessTextarea
          ref={ref}
          className={clsx([
            textAreaClassNames,
            // Basic layout
            'relative block h-full w-full appearance-none rounded-lg px-[calc(theme(spacing[3.5])-1px)] py-[calc(theme(spacing[2.5])-1px)] sm:px-[calc(theme(spacing.3)-1px)] sm:py-[calc(theme(spacing[1.5])-1px)]',
            'text-md font-medium text-gray-950 placeholder:text-gray-800',
            'border border-gray-500 shadow-sm',
            'bg-white',
            // Hide default focus styles
            'focus:outline-none',
            'data-[invalid]:border-red-500 data-[invalid]:data-[hover]:border-red-500',
            'disabled:border-gray-100',
            resizable ? 'resize-y' : 'resize-none',
          ])}
          {...props}
        />
      </span>
    )
  }
)
