import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/solid'
import { cva, type VariantProps } from 'class-variance-authority'
import clsx from 'clsx'

import { Text } from 'src/atoms'

const banner = cva('flex gap-3 p-4 border border-blackAlpha-100 rounded', {
  variants: {
    intent: {
      primary: ['bg-primary-200'],
      warning: ['bg-yellow-200'],
      danger: ['bg-red-200'],
    },
  },
  defaultVariants: {
    intent: 'primary',
  },
})

const icon = cva('h-4 w-4 shrink-0', {
  variants: {
    intent: {
      primary: ['text-gray-800'],
      warning: ['text-yellow-900'],
      danger: ['text-red-800'],
    },
  },
  defaultVariants: {
    intent: 'primary',
  },
})

type BannerVariantProps = VariantProps<typeof banner>

type BannerProps = {
  children: React.ReactNode
  className?: string
  icon?: React.ReactElement
} & BannerVariantProps

export const Banner = (props: BannerProps) => (
  <div className={clsx(banner({ intent: props.intent }), props.className)}>
    {/* NOTE: Adding pt-[2px] so that the icon aligns with the text that has a line height. */}
    <div className="pt-[2px]">
      {props.icon ? (
        props.icon
      ) : props.intent === 'danger' ? (
        <ExclamationTriangleIcon className={icon({ intent: props.intent })} />
      ) : (
        <InformationCircleIcon className={icon({ intent: props.intent })} />
      )}
    </div>
    <div className="flex flex-col gap-1">{props.children}</div>
  </div>
)

export const BannerTitle = ({ children }: { children: React.ReactNode }) => (
  <Text className="text-sm text-gray-950" weight="semibold">
    {children}
  </Text>
)

export const BannerDescription = ({
  children,
}: {
  children: React.ReactNode
}) => <Text className="text-sm text-gray-950">{children}</Text>
