import { useCallback } from 'react'

import { Spinner, Container } from 'react-bootstrap'
import {
  CreateSkillMutation,
  CreateSkillMutationVariables,
  FindSkills,
  FindSkillsVariables,
} from 'types/graphql'
import { EditSkillsInput } from 'types/index'

import type {
  CellSuccessProps,
  CellFailureProps,
  TypedDocumentNode,
} from '@redwoodjs/web'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { Text } from 'src/atoms'
import { DELETE_SKILL_MUTATION } from 'src/components/Skill/Skill'
import SkillForm from 'src/components/Skill/SkillForm'
import { SkillSchema } from 'src/schemas'

export const QUERY: TypedDocumentNode<FindSkills, FindSkillsVariables> = gql`
  query FindSkills {
    skills {
      id
      name
      createdAt
      updatedAt
      userId
    }
  }
`

const CREATE_SKILL_MUTATION: TypedDocumentNode<
  CreateSkillMutation,
  CreateSkillMutationVariables
> = gql`
  mutation CreateSkillMutation($input: CreateSkillInput!) {
    createSkill(input: $input) {
      id
    }
  }
`

type IntrinsicProps = {
  showSaveButton?: boolean
  editSkillsInput: EditSkillsInput
}

export const Loading = () => (
  <Container style={{ minHeight: '50vh' }}>
    <Spinner animation="border" role="status">
      <Text weight="semibold" className="text-sm text-gray-700">
        Loading...
      </Text>
    </Spinner>
  </Container>
)

export const Failure = ({ error }: CellFailureProps) => (
  <>{error ? <div className="rw-cell-error">{error?.message}</div> : null}</>
)

type SuccessProps = CellSuccessProps<FindSkills, FindSkillsVariables> &
  IntrinsicProps

export const Success = ({
  editSkillsInput,
  skills,
  showSaveButton,
}: SuccessProps) => {
  const [createSkill, { loading: createSkillLoading }] = useMutation(
    CREATE_SKILL_MUTATION,
    {
      // * We purposely don't show a success toast here because skills can be added
      // * in rapid succession from the suggestions and causes the toasts to take up
      // * a large amount of the screen.

      onError: (error) => {
        toast.error(error.message)
      },
      refetchQueries: [{ query: QUERY }],
      awaitRefetchQueries: true,
    }
  )

  const [deleteSkill, { loading: deleteSkillLoading }] = useMutation(
    DELETE_SKILL_MUTATION,
    {
      onCompleted: () => {
        toast.success('Skill deleted')
      },
      onError: (error) => {
        toast.error(error.message)
      },
      refetchQueries: [{ query: QUERY }],
      awaitRefetchQueries: true,
    }
  )

  const handleSubmit = useCallback(
    (input: SkillSchema) => {
      createSkill({ variables: { input } })
    },
    [createSkill]
  )

  const handleDelete = useCallback(
    (id: number) => {
      deleteSkill({ variables: { id } })
    },
    [deleteSkill]
  )

  return (
    <SkillForm
      showSaveButton={showSaveButton}
      skills={skills}
      onSubmit={handleSubmit}
      onDelete={handleDelete}
      loading={createSkillLoading || deleteSkillLoading}
      editSkillsInput={editSkillsInput}
    />
  )
}
