import type {
  CellSuccessProps,
  CellFailureProps,
  TypedDocumentNode,
} from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { Button, Text } from 'src/atoms'
import { useUpdateProfile } from 'src/hooks/mutations/useUpdateProfile'
import { mixpanelUtils } from 'src/lib/utils/mixpanel/mixpanel'

type FindEmploymentStatusesQuery = {
  employmentStatuses: {
    id: number
    title: string
  }[]
}

export const QUERY: TypedDocumentNode<FindEmploymentStatusesQuery> = gql`
  query FindEmploymentStatuses {
    employmentStatuses {
      id
      title
    }
  }
`

export const Loading = () => (
  <Text weight="semibold" className="text-sm text-gray-700">
    Loading...
  </Text>
)

// NOTE: Removed `Empty` as it should never be used.

export const Failure = ({ error }: CellFailureProps) => (
  <div className="rw-cell-error">{error?.message}</div>
)

export const Success = ({
  employmentStatuses,
  handleNext,
}: CellSuccessProps<FindEmploymentStatusesQuery> & {
  handleNext: () => void
}) => {
  const [updateProfile, { loading }] = useUpdateProfile({
    onError(error) {
      toast.error(error.message)
    },
  })

  const handleSelectEmploymentStatusId = (employmentStatusId: number) => {
    updateProfile({
      variables: {
        input: {
          employmentStatusId,
        },
      },
      onCompleted: () => {
        mixpanelUtils.updateMixpanelProfile({
          employmentStatusId,
        })

        handleNext()
      },
    })
  }

  return (
    <div className="flex flex-col gap-6">
      <div>
        <Text weight="bold" className="text-2xl">
          What best describes your current job situation?
        </Text>
        <Text className="text-md text-gray-900">
          You can change this at anytime.
        </Text>
      </div>
      <div className="flex flex-col gap-3">
        {employmentStatuses.map((employmentStatus) => {
          return (
            <Button
              key={employmentStatus.id}
              loading={loading} // ? Use `disabled` instead?
              onClick={() =>
                handleSelectEmploymentStatusId(employmentStatus.id)
              }
              outline
            >
              {employmentStatus.title}
            </Button>
          )
        })}
      </div>
    </div>
  )
}
