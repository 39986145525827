import LaborupLogo from 'src/components/LaborupLogo/LaborupLogo'

export default function CampaignLandingPageLayout({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <div>
      {/* TODO: Better reusability with the `SigninSignupLayout`? */}
      <div className="border-b border-gray-400 px-8 py-4">
        {/* NOTE: Not clickable so user doesn't navigate away from page. */}
        <LaborupLogo className="h-8 w-auto" />
      </div>
      <div className="flex flex-col items-center p-8 bg-gray-50">
        <div className="max-w-screen-sm">{children}</div>
      </div>
    </div>
  )
}
