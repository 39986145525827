import { Button } from 'src/atoms'

export function Footer({
  handleNext,
  isLastStep,
  loading,
}: {
  handleNext: () => void | Promise<void>
  isLastStep: boolean
  loading: boolean
}) {
  return (
    <>
      {/* Hack to ensure sticky buttons don't cover up content on bottom as well as give room for mobile navigation bar. */}
      <div className="h-40" />
      <div className="fixed bottom-0 left-0 border-t border-gray-200 flex gap-4 px-8 py-4 bg-white w-full">
        <Button
          className="w-full"
          loading={loading}
          onClick={async () => {
            await handleNext()
          }}
        >
          {isLastStep ? 'Complete' : 'Next'}
        </Button>
      </div>
    </>
  )
}
