import React from 'react'

import { cva } from 'class-variance-authority'
import clsx from 'clsx'

import { ProgressBarColor, ProgressBarProps, ProgressBarSize } from './types'

const progressBarOuterVariants = cva('w-full rounded-lg', {
  variants: {
    color: {
      [ProgressBarColor.primary]: 'bg-gray-400',
      [ProgressBarColor.green]: 'bg-green-950',
      [ProgressBarColor.yellow]: 'bg-yellow-950',
      [ProgressBarColor.red]: 'bg-red-950',
    },
    size: {
      [ProgressBarSize.xs]: 'h-1',
      [ProgressBarSize.sm]: 'h-2',
    },
  },
  defaultVariants: {
    color: ProgressBarColor.primary,
    size: ProgressBarSize.xs,
  },
})

const progressBarInnerVariants = cva('rounded-lg', {
  variants: {
    color: {
      [ProgressBarColor.primary]: 'bg-primary-700',
      [ProgressBarColor.green]: 'bg-green-800',
      [ProgressBarColor.yellow]: 'bg-yellow-800',
      [ProgressBarColor.red]: 'bg-red-800',
    },
    size: {
      [ProgressBarSize.xs]: 'h-1',
      [ProgressBarSize.sm]: 'h-2',
    },
  },
  defaultVariants: {
    color: ProgressBarColor.primary,
    size: ProgressBarSize.xs,
  },
})

export const ProgressBar = React.forwardRef(function ProgressBar(
  { color, progress, size, className, ...props }: ProgressBarProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <div
      ref={ref}
      className={clsx(progressBarOuterVariants({ color, size }), className)}
      {...props}
    >
      <div
        style={{ width: `${progress}%`, transition: 'width 512ms ease-out' }}
        className={progressBarInnerVariants({ color, size })}
      ></div>
    </div>
  )
})
