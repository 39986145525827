import {
  AcademicCapIcon,
  BookOpenIcon,
  BriefcaseIcon,
  CurrencyDollarIcon,
  HomeIcon,
} from '@heroicons/react/24/solid'

import { routes, useLocation } from '@redwoodjs/router'

import { MagnifyingGlassIcon } from 'src/atoms'
import NewEndorsementDialogCellWrapper from 'src/components/Endorsement/NewEndorsementDialogCellWrapper'
import InAppOnboardingDialogCell from 'src/components/InAppOnboardingDialogCell'
import { TopNavbar } from 'src/components/TopNavbar/TopNavbar'

const UserLayout = ({ children }) => {
  const { pathname } = useLocation()

  const navigationItems = [
    {
      name: 'Home',
      to: routes.home(),
      current: pathname === routes.home(),
      icon: HomeIcon,
      children: [],
    },
    {
      name: 'Profile',
      to: routes.profile(),
      current: pathname === routes.profile(),
      icon: BriefcaseIcon,
      children: [],
    },
    {
      name: 'Find Jobs',
      to: routes.searchJobs(),
      current:
        pathname === routes.searchJobs() ||
        pathname === routes.matchedJobs() ||
        pathname === routes.savedJobs() ||
        pathname === routes.laborupJobs() ||
        new RegExp(
          `^${routes.laborupJob({ id: '.*' }).replace(/\.\*/, '.*')}$`
        ).test(pathname),
      icon: MagnifyingGlassIcon,
      children: [],
    },
    {
      name: 'Career Resources',
      to: '', // This is a top-level navigation item so it doesn't need a `to`.
      current: pathname === routes.salary() || pathname === routes.upskilling(),
      icon: BookOpenIcon,
      children: [
        {
          name: 'Salary Estimator',
          to: routes.salary(),
          current: pathname === routes.salary(),
          icon: CurrencyDollarIcon,
        },
        {
          name: 'Find Classes',
          to: routes.upskilling(),
          current: pathname === routes.upskilling(),
          icon: AcademicCapIcon,
        },
      ],
    },
  ]

  return (
    <main className="bg-gray-50">
      <TopNavbar navigationItems={navigationItems} />
      <div className="min-h-screen pt-14 flex justify-center">{children}</div>
      <InAppOnboardingDialogCell />
      <NewEndorsementDialogCellWrapper />
    </main>
  )
}

export default UserLayout
