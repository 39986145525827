import * as Sentry from '@sentry/react'

const dsn = process.env.SENTRY_DSN
const environment = process.env.NODE_ENV || 'development'

const BOT_USER_AGENTS = [
  'googlebot',
  'bingbot',
  'slurp',
  'duckduckbot',
  'baiduspider',
  'yandexbot',
  'facebot',
  'ia_archiver',
  'fban',
]

function isBot(userAgent: string) {
  return BOT_USER_AGENTS.some((bot) => userAgent.toLowerCase().includes(bot))
}

try {
  if (window) {
    if (environment === 'development') {
      Sentry.init({
        dsn,
        maxValueLength: 8192,
        environment,
        replaysSessionSampleRate: 0.2,
        replaysOnErrorSampleRate: 1.0,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.httpClientIntegration(),
          Sentry.replayIntegration({
            // Additional SDK configuration goes in here, for example:
            // maskAllText: true,
            maskAllText: false,
            blockAllMedia: false,
            networkDetailAllowUrls: [window.location.origin],
            networkRequestHeaders: ['X-Custom-Header'],
            networkResponseHeaders: ['X-Custom-Header'],
          }),
          Sentry.replayCanvasIntegration(),
        ],
        tracesSampleRate: 1.0,
      })
    }
  }

  if (environment === 'production') {
    Sentry.init({
      dsn,
      maxValueLength: 3000,
      environment,
      beforeSend: (event) => {
        if (navigator && navigator.userAgent && isBot(navigator.userAgent)) {
          return null
        }
        return event
      },
      replaysSessionSampleRate: 0.5,
      replaysOnErrorSampleRate: 1.0,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.httpClientIntegration(),
        Sentry.replayIntegration({
          // Additional SDK configuration goes in here, for example:
          maskAllText: false,
          blockAllMedia: false,
          networkDetailAllowUrls: [window.location.origin],
          networkRequestHeaders: ['X-Custom-Header'],
          networkResponseHeaders: ['X-Custom-Header'],
        }),
        Sentry.replayCanvasIntegration(),
      ],
      tracesSampleRate: 0.5,
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
} catch (e) {
  console.info('Not in Browser Environment')
}

export default Sentry
