import { useCallback, useMemo } from 'react'

import { EditProfile, EditProfileVariables } from 'types/graphql'

import { navigate } from '@redwoodjs/router'
import type {
  CellSuccessProps,
  CellFailureProps,
  TypedDocumentNode,
} from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { Text } from 'src/atoms'
import ProfileForm from 'src/components/Profile/ProfileForm'
import { useUpdateProfile } from 'src/hooks/mutations/useUpdateProfile'
import { logError } from 'src/lib/utils'
import { ProfileSchema } from 'src/schemas'

export const QUERY: TypedDocumentNode<EditProfile, EditProfileVariables> = gql`
  query EditProfile {
    profile: profile {
      firstName
      lastName
      phone
      address
      location
      employmentStatusId
      desiredPosition
      yoeRangeId
      payBasis
      currentPay
      desiredPay
    }
  }
`

export const UPDATE_AVATAR_FILE_MUTATION = gql`
  mutation UpdateAvatarFileMutation($input: UpdateAvatarFileInput!) {
    updateAvatarFile(input: $input) {
      id
      url
      createdAt
      updatedAt
      userId
    }
  }
`

type IntrinsicProps = {
  redirectTo: string
}

type SuccessProps = CellSuccessProps<EditProfile, EditProfileVariables> &
  IntrinsicProps
type FailureProps = CellFailureProps<EditProfileVariables> & IntrinsicProps

export const Loading = () => (
  <Text weight="semibold" className="text-sm text-gray-700">
    Loading...
  </Text>
)

export const Failure = ({ error }: FailureProps) => (
  <>{error ? <div className="rw-cell-error">{error.message}</div> : null}</>
)

export const Success = ({ redirectTo, profile }: SuccessProps) => {
  const [updateProfile] = useUpdateProfile({
    onCompleted: () => {
      toast.success('Profile updated')
      navigate(redirectTo)
    },
    onError: (error) => {
      logError(error)
      toast.error(error.message)
    },
  })

  const handleSubmit = useCallback(
    (data: ProfileSchema) => {
      const input = {
        firstName: data.firstName,
        lastName: data.lastName,
        location: data.location,
        employmentStatusId: data.employmentStatusId,
        desiredPosition: data.desiredPosition,
        yoeRangeId: data.yoeRangeId,
        payBasis: data.payBasis,
        desiredPay: data.desiredPay,
        address: data.address || null,
        // @ts-expect-error: currentPay is set to empty string by the input
        currentPay: data.currentPay === '' ? null : data.currentPay,
      }
      updateProfile({ variables: { input } })
    },
    [updateProfile]
  )

  // typecasting is necessary as EditProfile is derived from DB types
  // where all the required fields have been marked as optional AFAIK
  const formProfile = useMemo(() => {
    return {
      ...profile,
      currentPay:
        profile.currentPay === null || profile.currentPay === undefined
          ? ''
          : profile.currentPay,
    }
  }, [profile]) as ProfileSchema

  return <ProfileForm profile={formProfile} onSubmit={handleSubmit} />
}
