import {
  FindInAppOnboardingDialogQuery,
  FindInAppOnboardingDialogQueryVariables,
} from 'types/graphql'

import {
  type CellFailureProps,
  type CellSuccessProps,
  type TypedDocumentNode,
} from '@redwoodjs/web'

import { sessionStorageUtils } from 'src/lib/utils/session-storage/session-storage'

import { DefaultOnboardingDialog } from './DefaultOnboardingDialog/DefaultOnboardingDialog'
import { YearsOfExperienceAndPayDialog } from './YearsOfExperienceAndPayDialog/YearsOfExperienceAndPayDialog'

export const QUERY: TypedDocumentNode<
  FindInAppOnboardingDialogQuery,
  FindInAppOnboardingDialogQueryVariables
> = gql`
  query FindInAppOnboardingDialogQuery {
    profile {
      id
      employmentStatusId
      desiredPosition
      currentPay
      desiredPay
      payBasis
      yoeRangeId
      source
    }
  }
`

// We do not want to show anything while loading.
export const Loading = () => null

// NOTE: Removed `Empty` as it should never be used.

// We do not want to show anything on failure.
// TODO: The error should be logged to Sentry.
export const Failure = ({ error: _error }: CellFailureProps) => null

export const Success = ({
  profile,
}: CellSuccessProps<
  FindInAppOnboardingDialogQuery,
  FindInAppOnboardingDialogQueryVariables
>) => {
  // If the user came through giving an endorsement,
  // we don't want to show any onboarding modals.
  if (profile.source === 'Endorsement') {
    return null
  }

  // If the user just came through access profile signup we
  // don't want to show them the onboarding modals just yet.
  // It is the responsibility of the other part of the app
  // to clear this flag when the user is ready to see the
  // onboarding modals.
  if (sessionStorageUtils.getIsNewAccessProfileSignup()) {
    return null
  }

  // If the user does not have an employment status or desired position,
  // we assume that they have entered through the default variant and
  // therefore we take them through the default onboarding modal.
  //
  // There is a possibility that the user has some info that is collected
  // during the default onboarding process but this is not expected and
  // for simplicity we will assume that they are missing all of it.
  if (!profile.employmentStatusId || !profile.desiredPosition) {
    return <DefaultOnboardingDialog />
  } else if (!profile.desiredPay || !profile.payBasis || !profile.yoeRangeId) {
    return <YearsOfExperienceAndPayDialog profile={profile} />
  }

  return null
}
