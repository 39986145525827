import {
  YearsOfExperienceRangesQuery,
  YearsOfExperienceRangesQueryVariables,
} from 'types/graphql'

import type {
  CellSuccessProps,
  CellFailureProps,
  TypedDocumentNode,
} from '@redwoodjs/web'

import { RHFSelect, RHFSelectProps } from 'src/reactHookForm'

// * BE should return ranges sorted by ordinal in ascending order.

export const QUERY: TypedDocumentNode<
  YearsOfExperienceRangesQuery,
  YearsOfExperienceRangesQueryVariables
> = gql`
  query YearsOfExperienceRangesQuery {
    yearsOfExperienceRanges {
      id
      range
    }
  }
`

// * We do not want to show anything while loading since this is a select input.
export const Loading = () => null

// * Removed `Empty` as it should never be used.

export const Failure = ({ error }: CellFailureProps) => (
  <>{error ? <div className="rw-cell-error">{error.message}</div> : null}</>
)

export const Success = ({
  yearsOfExperienceRanges,
  updating: _updating,
  queryResult: _queryResult,
  ...selectProps
}: CellSuccessProps<
  YearsOfExperienceRangesQuery,
  YearsOfExperienceRangesQueryVariables
> &
  RHFSelectProps) => {
  return (
    <RHFSelect {...selectProps}>
      {yearsOfExperienceRanges.map((yearsOfExperienceRange) => (
        <option
          key={yearsOfExperienceRange.id}
          value={yearsOfExperienceRange.id}
        >
          {yearsOfExperienceRange.range}
        </option>
      ))}
    </RHFSelect>
  )
}
