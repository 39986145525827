import {
  BatchCreateCertificationsMutation,
  BatchCreateCertificationsMutationVariables,
} from 'types/graphql'

import { TypedDocumentNode, useMutation } from '@redwoodjs/web'

const BATCH_CREATE_CERTIFICATIONS_MUTATION: TypedDocumentNode<
  BatchCreateCertificationsMutation,
  BatchCreateCertificationsMutationVariables
> = gql`
  mutation BatchCreateCertificationsMutation(
    $inputs: [CreateCertificationInput!]!
  ) {
    batchCreateCertifications(inputs: $inputs) {
      id
    }
  }
`

export function useBatchCreateCertificationsMutation() {
  return useMutation(BATCH_CREATE_CERTIFICATIONS_MUTATION)
}
