import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import { UpdateProfileInput } from 'types/graphql'

import {
  Button,
  Label,
  Field,
  FieldGroup,
  Fieldset,
  RequiredAsterisk,
} from 'src/atoms'
import SalaryPrefix from 'src/components/Forms/SalaryPrefix'
import SalarySuffix from 'src/components/Forms/SalarySuffix'
import SelectEmploymentStatusCell from 'src/components/SelectEmploymentStatusCell'
import SelectYearsOfExperienceRangeCell from 'src/components/YearsOfExperienceRange/SelectYearsOfExperienceRangeCell'
import { jobTitleOptions, payBasisOptions } from 'src/lib/constants'
import {
  RHFAutocompleteInput,
  RHFInput,
  RHFLocationSelect,
  RHFSelect,
} from 'src/reactHookForm'
import { profileSchema, ProfileSchema } from 'src/schemas'

type Props = {
  onSubmit: (input: UpdateProfileInput) => void
  profile: ProfileSchema
  avatarFile?: string
}

const ProfileForm = ({ profile, onSubmit }: Props) => {
  const methods = useForm({
    defaultValues: profile,
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver<ProfileSchema>(profileSchema),
  })

  const watchPayBasis = methods.watch<'payBasis'>('payBasis')

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Fieldset>
          <FieldGroup>
            <Field>
              <Label>
                First Name
                <RequiredAsterisk />
              </Label>
              <RHFInput<ProfileSchema>
                name="firstName"
                placeholder="Enter your first name"
              />
            </Field>
            <Field>
              <Label>
                Last Name
                <RequiredAsterisk />
              </Label>
              <RHFInput<ProfileSchema>
                name="lastName"
                placeholder="Enter your last name"
              />
            </Field>
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-2">
              <Field>
                <Label>
                  Desired Work Location
                  <RequiredAsterisk />
                </Label>
                <RHFLocationSelect<ProfileSchema>
                  name="location"
                  placeholder="Location where you want to work"
                  isClearable
                  types={['(cities)']}
                />
              </Field>
              <Field>
                <Label>Your Address</Label>
                <RHFLocationSelect<ProfileSchema>
                  name="address"
                  placeholder="Your Address"
                  isClearable
                />
              </Field>
            </div>
            <Field>
              <Label>
                Desired Position
                <RequiredAsterisk />
              </Label>
              <RHFAutocompleteInput<ProfileSchema>
                name="desiredPosition"
                isSearchable
                isClearable
                options={jobTitleOptions}
                placeholder="Ex: CNC Machinist"
              />
            </Field>
            <Field>
              <Label>
                Years of manufacturing experience
                <RequiredAsterisk />
              </Label>
              <SelectYearsOfExperienceRangeCell name="yoeRangeId" />
            </Field>
            <Field>
              <Label>
                Job Status
                <RequiredAsterisk />
              </Label>
              <SelectEmploymentStatusCell name="employmentStatusId" />
            </Field>
            <Field>
              <Label>
                Are you paid on an hourly or yearly basis?
                <RequiredAsterisk />
              </Label>
              <RHFSelect name="payBasis" placeholder="Select a pay basis">
                {payBasisOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </RHFSelect>
            </Field>
            <Field>
              <Label>Current pay</Label>
              <RHFInput
                type="number"
                name="currentPay"
                placeholder={watchPayBasis ? '' : 'Select a pay basis above'}
                disabled={!watchPayBasis}
                prefix={watchPayBasis && <SalaryPrefix />}
                suffix={
                  watchPayBasis && <SalarySuffix payBasis={watchPayBasis} />
                }
              />
            </Field>
            <Field>
              <Label>
                Desired pay
                <RequiredAsterisk />
              </Label>
              <RHFInput
                type="number"
                name="desiredPay"
                placeholder={watchPayBasis ? '' : 'Select a pay basis above'}
                disabled={!watchPayBasis}
                prefix={watchPayBasis && <SalaryPrefix />}
                suffix={
                  watchPayBasis && <SalarySuffix payBasis={watchPayBasis} />
                }
              />
            </Field>
          </FieldGroup>
        </Fieldset>
        <div className="w-100 text-color-950 flex flex-col">
          <Button type="submit">Save</Button>
        </div>
      </form>
    </FormProvider>
  )
}

export default ProfileForm
