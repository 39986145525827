import { useEffect } from 'react'

import { Button, Text } from 'src/atoms'
import { DefaultOnboardingDialogStickyFooterContainer } from 'src/components/InAppOnboardingDialogCell/DefaultOnboardingDialog/DefaultOnboardingDialogStickyFooterContainer/DefaultOnboardingDialogStickyFooterContainer'
import EditSkillsCell from 'src/components/Skill/EditSkillsCell'

export function SkillsStep({
  desiredPosition,
  shouldOnlyRecommendSoftSkills,
  handleBack,
  handleNext,
  scrollToTop,
}: {
  desiredPosition: string
  shouldOnlyRecommendSoftSkills: boolean
  handleBack: () => void
  handleNext: () => void
  scrollToTop: () => void
}) {
  useEffect(() => {
    // When we arrive at this step make sure we scroll to the top of the page.
    scrollToTop()
  }, [scrollToTop])

  return (
    <div className="flex flex-col gap-6">
      <div>
        <Text weight="bold" className="text-2xl">
          Add your skills as a {desiredPosition}
        </Text>
        <Text className="text-md text-gray-900">
          You can change this anytime in your profile.
        </Text>
      </div>
      <EditSkillsCell
        editSkillsInput={{
          variant: 'onboarding',
          desiredPosition,
          shouldOnlyRecommendSoftSkills,
        }}
      />
      <DefaultOnboardingDialogStickyFooterContainer>
        <Button color="white" className="w-full" onClick={handleBack}>
          Back
        </Button>
        <Button className="w-full" onClick={handleNext}>
          Next
        </Button>
      </DefaultOnboardingDialogStickyFooterContainer>
    </div>
  )
}
