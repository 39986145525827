import { Button, Dialog, DialogDescription, DialogTitle } from 'src/atoms'

type Props<
  TId extends string | number | null | undefined =
    | string
    | number
    | null
    | undefined,
> = {
  open: boolean
  onCancel: () => void
  onSubmit: (id: TId) => void
  id: TId
}

const DeleteDialog = ({ open, onCancel, onSubmit, id }: Props) => {
  return (
    <Dialog open={open} onClose={onCancel} className="relative z-50">
      <DialogTitle showCloseIcon={false}>
        Are you sure you want to delete this?
      </DialogTitle>
      <DialogDescription>
        Please note that this action cannot be undone.
      </DialogDescription>
      <div className="flex gap-4 mt-6 justify-end">
        <Button plain onClick={onCancel}>
          Cancel
        </Button>
        <Button color="red" onClick={() => onSubmit(id)}>
          Delete
        </Button>
      </div>
    </Dialog>
  )
}

export default DeleteDialog
