import React from 'react'

type SVGProps = React.SVGProps<never>

export const ChevronDownIcon = (props: SVGProps) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    stroke="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.21967 6.60651C4.51256 6.31362 4.98744 6.31362 5.28033 6.60651L8 9.32618L10.7197 6.60651C11.0126 6.31362 11.4874 6.31362 11.7803 6.60651C12.0732 6.89941 12.0732 7.37428 11.7803 7.66717L8.53033 10.9172C8.23744 11.2101 7.76256 11.2101 7.46967 10.9172L4.21967 7.66717C3.92678 7.37428 3.92678 6.8994 4.21967 6.60651Z"
      fill="currentColor"
    />
  </svg>
)
