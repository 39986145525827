import { jobTitles } from 'src/lib/constants'

const jobTitlesSet = new Set(jobTitles)

export const endorsementsUtils = {
  /**
   * Check if a desired position is within the set of valid desired positions.
   *
   * "Other" or free-form desired positions that were enabled in an earlier
   * version of the application are generally considered invalid.
   */
  isValidDesiredPosition: (desiredPosition: string) => {
    return jobTitlesSet.has(desiredPosition)
  },
}
