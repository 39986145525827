import React from 'react'

import { FieldValues, useFormContext, Path, Controller } from 'react-hook-form'

import { LocationSelect, LocationSelectProps, ErrorMessage } from 'src/atoms'

export type RHFLocationSelectProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>
  description?: React.ReactNode
  onChange?: LocationSelectProps['onChange']
  clearErrorOnChange?: boolean
} & Omit<LocationSelectProps, 'value' | 'onChange'>

export const RHFLocationSelect = <TFieldValues extends FieldValues>({
  name,
  description,
  clearErrorOnChange,
  onChange: handleChangeProps,
  ...props
}: RHFLocationSelectProps<TFieldValues>) => {
  const { control, clearErrors } = useFormContext<TFieldValues>()

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange: handleChangeField, ...field },
        fieldState: { error },
      }) => {
        const handleChange = (val: string) => {
          if (clearErrorOnChange) {
            clearErrors(name)
          }
          handleChangeField(val)
          handleChangeProps?.(val)
        }

        return (
          <>
            <LocationSelect
              onChange={handleChange}
              {...field}
              {...props}
              blurInputOnSelect
            />
            {description}
            <ErrorMessage className="ms-1.5" message={error?.message} />
          </>
        )
      }}
    />
  )
}
