import React from 'react'

type SVGProps = React.SVGProps<never>

export const MagnifyingGlassIcon = (props: SVGProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    stroke="currentColor"
    data-slot="icon"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4568 13.7826C11.4197 14.5478 10.1377 15 8.75 15C5.29822 15 2.5 12.2018 2.5 8.75C2.5 5.29822 5.29822 2.5 8.75 2.5C12.2018 2.5 15 5.29822 15 8.75C15 10.1377 14.5478 11.4197 13.7826 12.4568L17.2254 15.8996C17.5915 16.2657 17.5915 16.8593 17.2254 17.2254C16.8593 17.5915 16.2657 17.5915 15.8996 17.2254L12.4568 13.7826ZM13.125 8.75C13.125 11.1662 11.1662 13.125 8.75 13.125C6.33375 13.125 4.375 11.1662 4.375 8.75C4.375 6.33375 6.33375 4.375 8.75 4.375C11.1662 4.375 13.125 6.33375 13.125 8.75Z"
      fill="#11181C"
    />
  </svg>
)
