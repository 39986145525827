import { Dialog } from 'src/atoms'
import ParseResumeCell from 'src/components/ResumeImport/ParseResumeCell'

const dialogScrollableId = 'parse-resume-and-complete-profile-dialog'

export function ParseResumeDialog({ base64Resume }: { base64Resume: string }) {
  return (
    <Dialog
      className="p-6"
      scrollableId={dialogScrollableId}
      onClose={() => {}} // This prevents the dialog from being manually closed.
      size="fullScreen"
      open
    >
      <ParseResumeCell
        base64Resume={base64Resume}
        parentDialogScrollableId={dialogScrollableId}
      />
    </Dialog>
  )
}
