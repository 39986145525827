import { useEffect } from 'react'

import { navigate, routes, useParams } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import NewEndorsementDialogCell from 'src/components/Endorsement/NewEndorsementDialogCell'

/**
 * The purpose of this component is to prevent the `NewEndorsementDialogCell` from being
 * rendered and making API calls when the `endorsementHash` is not present in the URL.
 */
export default function NewEndorsementDialogCellWrapper() {
  const { currentUser } = useAuth()
  const { endorsementHash } = useParams()

  useEffect(() => {
    const referralHash = currentUser?.referralHash

    // If a user has clicked on their own endorsement link, redirect them to the new endorsement page.
    if (referralHash && endorsementHash && referralHash === endorsementHash) {
      navigate(routes.newEndorsement())
    }
  }, [currentUser, endorsementHash])

  if (!endorsementHash) {
    return null
  }

  return <NewEndorsementDialogCell endorsementHash={endorsementHash} />
}
