import clsx from 'clsx'

import { SpinnerIcon } from '../Icons'

export const Spinner = ({ className }: { className?: string }) => {
  return (
    <SpinnerIcon
      className={clsx(
        'animate-spinner origin-[50%_50%] inline-block',
        className
      )}
    />
  )
}
