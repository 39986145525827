import { useEffect, useState } from 'react'

import { screenSize, type ScreenSize } from 'src/screenSize'

// use cautiously, causes a rerender on every resize
export function useScreenWidth() {
  const [screenWidth, setScreenWidth] = useState(window?.innerWidth)
  const onResize = () => setScreenWidth(window?.innerWidth)
  useEffect(() => {
    window?.addEventListener?.('resize', onResize)
    return () => window?.removeEventListener?.('resize', onResize)
  }, [])

  return screenWidth
}

export function useScreenSize(): ScreenSize {
  const [size, setSize] = useState<ScreenSize>('lg')

  const onResize = () => {
    const width = window?.innerWidth || 0
    if (width > screenSize['2xl']) setSize('2xl')
    else if (width > screenSize.xl) setSize('xl')
    else if (width > screenSize.lg) setSize('lg')
    else if (width > screenSize.md) setSize('md')
    else setSize('sm')
  }

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  return size
}
