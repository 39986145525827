import React from 'react'

import { clsx } from 'clsx'

type CardProps = { children?: React.ReactNode; className?: string }

export const Card = React.forwardRef(function Card(
  { className, children, ...props }: CardProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const classes = clsx(
    className,
    'p-4 md:px-6 border border-gray-400 rounded-xl bg-white'
  )

  return (
    <div ref={ref} className={classes} {...props}>
      {children}
    </div>
  )
})
