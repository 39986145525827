import { FindPrimaryResumeQuery } from 'types/graphql'

import { TypedDocumentNode } from '@redwoodjs/web'

export const FIND_PRIMARY_RESUME_QUERY: TypedDocumentNode<FindPrimaryResumeQuery> = gql`
  query FindPrimaryResumeQuery {
    primaryResumeFile {
      id
    }
  }
`
