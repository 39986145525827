import {
  UpdateProfileMutation,
  UpdateProfileMutationVariables,
} from 'types/graphql'

import { TypedDocumentNode, useMutation } from '@redwoodjs/web'

const UPDATE_PROFILE_MUTATION: TypedDocumentNode<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
> = gql`
  mutation UpdateProfileMutation($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      id
    }
  }
`

export function useUpdateProfile(
  options?: GraphQLMutationHookOptions<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >
) {
  return useMutation(UPDATE_PROFILE_MUTATION, options)
}
