import {
  BatchCreateEducationsMutation,
  BatchCreateEducationsMutationVariables,
} from 'types/graphql'

import { TypedDocumentNode, useMutation } from '@redwoodjs/web'

const BATCH_CREATE_EDUCATIONS_MUTATION: TypedDocumentNode<
  BatchCreateEducationsMutation,
  BatchCreateEducationsMutationVariables
> = gql`
  mutation BatchCreateEducationsMutation($inputs: [CreateEducationInput!]!) {
    batchCreateEducations(inputs: $inputs) {
      id
    }
  }
`

export function useBatchCreateEducationsMutation() {
  return useMutation(BATCH_CREATE_EDUCATIONS_MUTATION)
}
