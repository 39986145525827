import { Text } from 'src/atoms'

export function Header({
  title,
  description,
}: {
  title: string
  description?: string
}) {
  return (
    <div>
      <Text weight="bold" className="text-2xl">
        {title}
      </Text>
      {description && (
        <Text className="text-md text-gray-900">{description}</Text>
      )}
    </div>
  )
}
