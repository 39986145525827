import { Card } from 'src/atoms'
import ProfileCardCell from 'src/components/Profile/ProfileCardCell'
import ProfileProgressCell from 'src/components/ProfileProgressCell'
import ResumeImportCell from 'src/components/ResumeImport/ResumeImportCell'
import { useScreenSize } from 'src/hooks/useResize'

const AccountLayout = ({ children }) => {
  const screenSize = useScreenSize()

  return (
    // NOTE: This `id` is used for navigation on the profile page on mobile.
    // Please be very cautious if you decide to make changes to the id.
    <div className="w-full" id="profile">
      <div className="flex flex-col justify-center gap-3 md:gap-6 md:flex-row">
        <div className="md:w-80 flex flex-col-reverse md:flex-col gap-3 shrink-0">
          {/*
           * NOTE: This `id` is used for navigation on the profile page on mobile.
           * Please be very cautious if you decide to make changes to the id.
           */}
          {/* @ts-expect-error: didn't spend time to fix this */}
          <Card className="p-4 md:p-6" id="basicInfo">
            <ProfileCardCell />
          </Card>
          {/* IMPORTANT: We use this approach instead of CSS to avoid unnecessary network requests. */}
          {screenSize === 'sm' && (
            <>
              <ProfileProgressCell />
              <ResumeImportCell />
            </>
          )}
        </div>
        <div className="mb-4 grow md:max-w-6xl">{children}</div>
      </div>
    </div>
  )
}

export default AccountLayout
