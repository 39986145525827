import React, { useEffect, useMemo, useRef, useState } from 'react'

import { Button as HeadlessButton } from '@headlessui/react'
import clsx from 'clsx'

import { Link } from '../Link/Link'
import { Spinner } from '../Spinner'

import { ButtonProps, ButtonSize } from './types'

import './styles.css'

const ButtonSpinner = () => <Spinner className="fixed my-0.5 size-5" />

const styles = {
  base: [
    // Base
    'relative isolate inline-flex items-center justify-center gap-x-2 rounded-lg border text-base/6 font-semibold cursor-pointer shadow-sm',

    // Disabled
    'data-[disabled]:opacity-50 data-[disabled]:cursor-not-allowed',

    // Icon
    '[&>[data-slot=icon]]:size-4 [&>[data-slot=icon]]:shrink-0 [&>[data-slot=icon]]:text-[--btn-icon] [&>[data-slot=icon]]:sm:size-4',
  ],
  solid: [
    // Optical border, implemented as the button background to avoid corner artifacts
    'border-transparent bg-[--btn-border] border-[--btn-border]',

    // Button background, implemented as foreground layer to stack on top of pseudo-border layer
    'before:absolute before:inset-0 before:-z-10 before:rounded-[calc(theme(borderRadius.lg)-1px)] before:bg-[--btn-bg]',

    // Drop shadow, applied to the inset `before` layer so it blends with the border
    'before:shadow',

    // Shim/overlay, inset to match button foreground and used for hover state + highlight shadow
    'after:absolute after:inset-0 after:-z-10 after:rounded-[calc(theme(borderRadius.lg)-1px)]',

    // Inner highlight shadow
    'after:shadow-[shadow:inset_0_1px_theme(colors.white/15%)]',

    // White overlay on hover
    'after:data-[active]:bg-[--btn-hover-overlay] after:data-[hover]:bg-[--btn-hover-overlay]',

    // Disabled
    'before:data-[disabled]:shadow-none after:data-[disabled]:shadow-none',
  ],
  outline: [
    // Base
    'border-gray-500 text-gray-950 data-[hover]:bg-blackAlpha-50',
    'data-[focus]:outline data-[focus]:outline-2 data-[focus]:outline-primary-700',

    // Icon
    '[--btn-icon:theme(colors.gray.950)] data-[active]:[--btn-icon:theme(colors.gray.950)] data-[hover]:[--btn-icon:theme(colors.gray.950)]',
  ],
  plain: [
    // Base
    'border-transparent !border-0 text-gray-950 data-[hover]:bg-blackAlpha-50 !shadow-none',
    'data-[focus]:outline data-[focus]:outline-2 data-[focus]:outline-offset-20',
    // Icon
    '[--btn-icon:theme(colors.gray.950)] data-[active]:[--btn-icon:theme(colors.gray.950)] data-[hover]:[--btn-icon:theme(colors.gray.950)]',
  ],
  colors: {
    primary: [
      'text-white [--btn-bg:theme(colors.primary.700)] [--btn-border:theme(colors.primary.800)] [--btn-hover-overlay:theme(colors.primary.900)]',
      '[--btn-icon:theme(colors.white)]',
      'data-[focus]:outline data-[focus]:outline-2 data-[focus]:outline-offset-2 data-[focus]:outline-primary-700',
    ],
    primaryLight: [
      'text-primary-950 [--btn-bg:theme(colors.primary.400)] [--btn-border:theme(colors.primary.400)] [--btn-hover-overlay:theme(colors.primary.500)]',
      '[--btn-icon:theme(colors.primary.950)]',
      'data-[focus]:outline data-[focus]:outline-2 data-[focus]:outline-offset-2 data-[focus]:outline-primary-700',
    ],

    white: [
      'text-gray-950 [--btn-bg:theme(colors.gray.50)] [--btn-border:theme(colors.gray.500)] [--btn-hover-overlay:theme(colors.gray.300)] data-[active]:[--btn-border:theme(colors.blackAlpha.50)]',
      '[--btn-icon:theme(colors.gray.950)]',
    ],
    red: [
      'text-white [--btn-bg:theme(colors.red.800)] [--btn-border:theme(colors.red.800)] [--btn-hover-overlay:theme(colors.red.900)]',
      '[--btn-icon:theme(colors.white)]',
      'data-[focus]:outline data-[focus]:outline-2 data-[focus]:outline-offset-2 data-[focus]:outline-red-700',
    ],
  },
  size: {
    xs: 'text-xs font-semibold rounded-md',
    sm: 'text-sm font-semibold rounded-md',
    base: 'text-sm font-semibold rounded-lg',
    lg: 'text-sm font-semibold rounded-lg',
    xl: 'text-md font-semibold rounded-lg',
    '2xl': 'text-md font-semibold rounded-lg',
  },
}

const getPaddingClasses = (size: ButtonSize, isIcon?: boolean) => {
  switch (size) {
    case 'xs':
      return isIcon ? 'p-1' : 'px-2 py-1'
    case 'sm':
      return isIcon ? 'p-1.5' : 'px-2 py-1'
    case 'lg':
      return isIcon ? 'p-2.5' : 'px-3 py-2'
    case 'xl':
      return isIcon ? 'p-3' : 'px-4 py-2'
    case '2xl':
      return isIcon ? 'p-3' : 'px-4 py-[10px]'
    case 'base':
    default:
      return isIcon ? 'p-2' : 'px-2.5 py-1.5'
  }
}

export const Button = React.forwardRef(function Button(
  {
    size = 'base',
    isIcon,
    outline,
    plain,
    color = 'primary',
    className,
    children,
    spinDelay = 'normal',
    loading: parentLoading,
    ...props
  }: ButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const classes = clsx(
    className,
    getPaddingClasses(size, isIcon),
    styles.base,
    styles.size[size],
    outline
      ? styles.outline
      : plain
        ? styles.plain
        : clsx('button', styles.solid, styles.colors[color])
  )

  const [loading, setLoading] = useState(parentLoading)
  const loadingTimeoutId = useRef<ReturnType<typeof setTimeout> | undefined>()

  const spinTimeout = useMemo(() => {
    switch (spinDelay) {
      case 'none':
        return 0
      case 'small':
        return 128
      case 'normal':
        return 1024
    }
  }, [spinDelay])

  useEffect(() => {
    if (parentLoading) {
      loadingTimeoutId.current = setTimeout(() => {
        setLoading(true)
      }, spinTimeout)
    } else {
      clearTimeout(loadingTimeoutId.current)
      setLoading(false)
    }

    return () => clearTimeout(loadingTimeoutId.current)
  }, [parentLoading, spinTimeout])

  return 'href' in props ? (
    <Link {...props} className={classes}>
      <TouchTarget>{loading ? <ButtonSpinner /> : children}</TouchTarget>
    </Link>
  ) : (
    <HeadlessButton
      {...props}
      disabled={props.disabled || loading}
      className={clsx(classes)}
      ref={ref}
    >
      <TouchTarget>
        {loading ? (
          <>
            <ButtonSpinner />
            <span className="invisible">{children}</span>
          </>
        ) : (
          children
        )}
      </TouchTarget>
    </HeadlessButton>
  )
})

/* Expand the hit area to at least 44×44px on touch devices */
export function TouchTarget({ children }: { children: React.ReactNode }) {
  return (
    <>
      {children}
      <span
        className="absolute left-1/2 top-1/2 size-[max(100%,2.75rem)] -translate-x-1/2 -translate-y-1/2 [@media(pointer:fine)]:hidden"
        aria-hidden="true"
      />
    </>
  )
}
