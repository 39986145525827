import React from 'react'

type SVGProps = React.SVGProps<never>

export const PencilIcon = (props: SVGProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    stroke="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0126 2.8994C11.696 2.21599 12.804 2.21599 13.4874 2.8994C14.1709 3.58282 14.1709 4.69086 13.4874 5.37428L6.22601 12.6357C5.97064 12.8911 5.66746 13.0936 5.33379 13.2318L3.287 14.0796C3.00674 14.1957 2.68415 14.1315 2.46966 13.917C2.25516 13.7025 2.191 13.3799 2.3071 13.0997L3.15486 11.0531C3.29305 10.7195 3.49561 10.4164 3.75096 10.161L11.0126 2.8994Z"
      fill="currentColor"
    />
  </svg>
)
