import React from 'react'

import {
  FieldValues,
  useFormContext,
  Path,
  Controller,
  PathValue,
} from 'react-hook-form'

import {
  BulletListTextarea,
  BulletListTextareaProps,
  ErrorMessage,
} from 'src/atoms'

export type RHFBulletListTextareaProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>
  description?: React.ReactNode
  onChange?: BulletListTextareaProps['onChange']
  clearErrorOnChange?: boolean
} & Omit<BulletListTextareaProps, 'value' | 'onChange'>

export const RHFBulletListTextarea = <TFieldValues extends FieldValues>({
  name,
  description,
  onChange: handleChangeProps,
  clearErrorOnChange,
  ...props
}: RHFBulletListTextareaProps<TFieldValues>) => {
  const { control, clearErrors, setValue } = useFormContext<TFieldValues>()

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange: handleChangeField, ...field },
        fieldState: { error },
      }) => {
        const handleChange = (val: string[]) => {
          if (clearErrorOnChange) {
            clearErrors(name)
            setValue(name, val as PathValue<TFieldValues, Path<TFieldValues>>)
          } else {
            handleChangeField(val)
          }
          handleChangeProps?.(val)
        }
        return (
          <>
            <BulletListTextarea onChange={handleChange} {...field} {...props} />
            {description}
            <ErrorMessage className="ms-1.5" message={error?.message} />
          </>
        )
      }}
    />
  )
}
