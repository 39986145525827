import * as yup from 'yup'

import { Prettify, SigninMethod } from 'src/types'
import { PayBasis } from 'src/types'

// yup runs its tests in parallel, inorder to avoid running tests that
// make api requests, for example in case a previous test failed,
// we're adding the ability to run sequential tests
declare module 'yup' {
  interface StringSchema {
    sequence(funcList: (() => yup.StringSchema)[]): this
  }
}

yup.addMethod(yup.string, 'sequence', function (funcList) {
  return this.test(async (value, context) => {
    try {
      for (const func of funcList) {
        await func().validate(value)
      }
    } catch ({ message }) {
      return context.createError({ message })
    }
    return true
  })
})

function constant<const T extends {}>(value: T) {
  return yup.mixed({ check: (v): v is T => v === value })
}

export const workExperienceSchema = yup.object().shape({
  title: yup.string().required('Job title is required'),
  company: yup.string().required('Company name is required'),
  location: yup.string().required('Work location is required'),
  startMonth: yup.string().required('Required'),
  startYear: yup.string().required('Required'),
  endMonth: yup.string().when('currentPosition', {
    is: false,
    then: () => yup.string().required('Required'),
  }),
  endYear: yup.string().when('currentPosition', {
    is: false,
    then: () => yup.string().required('Required'),
  }),
  currentPosition: yup.boolean(),
  descriptions: yup.array().of(yup.string()),
})

export type WorkExperienceSchema = yup.InferType<typeof workExperienceSchema>

export const profileSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  address: yup.string().nullable(),
  location: yup.string().required('Work location is required'),
  employmentStatusId: yup.number().required('Please specify your job status'),
  desiredPosition: yup
    .string()
    .required('Please specify a position of interest'),
  yoeRangeId: yup.number().required('Years of experience is required'),
  payBasis: yup.string().required('Please specify a pay basis'),
  currentPay: yup
    .number()
    .transform((value) => (isNaN(value) ? null : parseFloat(value)))
    .nullable(),
  desiredPay: yup
    .number()
    .typeError('Desired pay is required')
    .positive('Desired pay must be a positive number')
    .required('Desired pay is required'),
})

export type ProfileSchema = yup.InferType<typeof profileSchema>

export const supportingDocumentSchema = yup.object({
  title: yup.string().required('Name is required'),
})

export type SupportingDocumentSchema = yup.InferType<
  typeof supportingDocumentSchema
>

export const resumeBuilderFileUploadSchema = yup.object({
  title: yup.string().required('Title is required'),
})

export type ResumeBuilderFileUploadSchema = yup.InferType<
  typeof resumeBuilderFileUploadSchema
>

export const jobSearchSchema = yup.object().shape({
  keywords: yup.string(),
  location: yup.string(),
  distance: yup
    .number()
    .transform((value) => parseFloat(value) || null)
    .nullable(),
})

export type JobSearchSchema = yup.InferType<typeof jobSearchSchema>

export const industrySchema = yup.object().shape({
  name: yup.string().required(),
})

export type IndustrySchema = yup.InferType<typeof industrySchema>

export const renameFileSchema = yup.object().shape({
  id: yup.number().required("Internal Error: resume doens't have an ID"),
  title: yup.string().required('Name is required'),
})

export type RenameFileSchema = yup.InferType<typeof renameFileSchema>

export const salarySearchSchema = yup.object().shape({
  jobTitle: yup.string().required('Job title is required'),
  yearsOfExperience: yup.string().required('Years of experience is required'),
  city: yup.string().required('City is required'),
})

export type SalarySearchSchema = yup.InferType<typeof salarySearchSchema>

export const salaryComparisonSchema = yup.object().shape({
  currentPay: yup
    .number()
    .transform((value) => parseFloat(value) || null)
    .required('Please enter current pay to compare salaries'),
  payBasis: yup
    .mixed<PayBasis>()
    .oneOf([PayBasis.Hourly, PayBasis.Yearly])
    .required('Internal Error: pay basis not assigned'),
})

export type SalaryComparisonSchema = yup.InferType<
  typeof salaryComparisonSchema
>

export const skillSchema = yup.object().shape({
  name: yup.string().required(),
})

export type SkillSchema = yup.InferType<typeof skillSchema>

export const endorsementSchema = yup.object().shape({
  technicalExperienceRating: yup
    .number()
    .transform((value) => parseInt(value) || null)
    .required('Please rate their technical experience'),
  leadershipRating: yup
    .number()
    .transform((value) => parseInt(value) || null)
    .required('Please rate their leadership'),
  teamworkRating: yup
    .number()
    .transform((value) => parseInt(value) || null)
    .required('Please rate their teamwork'),
  additionalContext: yup.string(),
})

export type EndorsementSchema = yup.InferType<typeof endorsementSchema>

export const inAppOnboardingUpdateProfileschema = yup.object().shape({
  currentPay: yup
    .number()
    .min(0, 'Current pay must be 0 or a positive number')
    .typeError('')
    .transform((value) => (isNaN(value) ? null : parseFloat(value)))
    .nullable(),
  desiredPay: yup
    .number()
    .positive('Desired pay must be a positive number')
    .typeError('Desired pay is required')
    .required('Desired pay is required'),
  payBasis: yup
    .mixed<PayBasis>()
    .oneOf([PayBasis.Hourly, PayBasis.Yearly])
    .required('Please specify a pay basis'),
  yoeRangeId: yup
    .number()
    .typeError('Years of experience is required')
    .required('Years of experience is required'),
})

export type InAppOnboardingUpdateProfileschema = yup.InferType<
  typeof inAppOnboardingUpdateProfileschema
>

export const phoneVerificationSchema = yup.object().shape({
  phone: yup
    .string()
    .required('Phone number is required')
    .min(11, 'Please enter a valid US phone number'),
  phoneNumberVerificationToken: yup.string().when('isCodeSent', {
    is: true,
    then: () => yup.string().required('Verification code is required'),
    otherwise: () => yup.string().notRequired(),
  }),
  isCodeSent: yup.boolean(),
})

export type PhoneVerificationSchema = yup.InferType<
  typeof phoneVerificationSchema
>

export const coursesSearchSchema = yup.object().shape({
  profession: yup.string().nullable(),
  format: yup.string().nullable(),
  location: yup.string().nullable(),
})

export type CoursesSearchSchema = yup.InferType<typeof coursesSearchSchema>

export const educationSchema = yup.object().shape({
  school: yup.string().required("School can't be empty"),
  degree: yup.string().required("Degree can't be empty"),
  location: yup.string().required("Location can't be empty"),
  startMonth: yup.string().required('Required'),
  startYear: yup.string().required('Required'),
  endMonth: yup.string().when('current', {
    is: false,
    then: () => yup.string().required('Required'),
  }),
  endYear: yup.string().when('current', {
    is: false,
    then: () => yup.string().required('Required'),
  }),
  current: yup.boolean(),
})

export type EducationSchema = yup.InferType<typeof educationSchema>

export const certificationSchema = yup.object().shape(
  {
    name: yup.string().required('Certification name is required'),
    organization: yup.string(),
    startMonth: yup.string().when('startYear', {
      is: (startYear: string) => !!startYear,
      then: () => yup.string().required('Month is required'),
      otherwise: () => yup.string(),
    }),
    startYear: yup.string().when('startMonth', {
      is: (startMonth: string) => !!startMonth,
      then: () => yup.string().required('Year is required'),
      otherwise: () => yup.string(),
    }),
    endMonth: yup.string().when('endYear', {
      is: (endYear: string) => !!endYear,
      then: () => yup.string().required('Month is required'),
      otherwise: () => yup.string(),
    }),
    endYear: yup.string().when('endMonth', {
      is: (endMonth: string) => !!endMonth,
      then: () => yup.string().required('Year is required'),
      otherwise: () => yup.string(),
    }),
    credentialId: yup.string(),
    credentialUrl: yup
      .string()
      .url('Please enter a valid URL scheme like https://www.example.com'),
  },
  [
    ['startMonth', 'startYear'],
    ['endMonth', 'endYear'],
  ]
)

export type CertificationSchema = yup.InferType<typeof certificationSchema>

const emailSchema = yup.object({
  signinMethod: constant(SigninMethod.email).required(),
  email: yup
    .string()
    .email('Invalid email')
    .required('Valid email is required'),
  phone: yup.string().optional(),
})

const phoneSchema = yup.object({
  signinMethod: constant(SigninMethod.phone).required(),
  email: yup.string().optional(),
  phone: yup
    .string()
    .matches(/^1[0-9]{10}$/, 'Phone number must be 10 digits')
    .required('Phone number is required'),
})

export const signinSchemaStepOne = yup.lazy((value) => {
  return value?.signinMethod === SigninMethod.email ? emailSchema : phoneSchema
})

export const signinSchemaStepTwo = yup.object().shape({
  password: yup
    .string()
    .matches(/^\d{6}$/, 'Code must be 6 digits')
    .required('Code is required'),
})

export type SigninSchemaStepOne = yup.InferType<typeof signinSchemaStepOne>
export type SigninSchemaStepTwo = yup.InferType<typeof signinSchemaStepTwo>
export type SigninSchema = Prettify<SigninSchemaStepOne & SigninSchemaStepTwo>

const getSignupSchemaAllFields = (
  testEmail: (email: string) => Promise<boolean>,
  testPhone: (phone: string) => Promise<boolean>
) =>
  yup.object({
    name: yup
      .string()
      .test(
        'is-first-last-name',
        'Please enter your first and last name',
        (value) => {
          if (value) {
            return value.trim().split(' ').length > 1
          }
          return false
        }
      )
      .required('Please enter your first and last name'),
    location: yup.string().required('Please enter a valid location'),
    email: yup.string().sequence([
      () =>
        yup.string().email('Invalid email').required('Valid email is required'),
      () =>
        yup
          .string()
          .test(
            'isUnique',
            'An account with this email already exists',
            async (value: string) => {
              return await testEmail(value)
            }
          ),
    ]),

    phone: yup.string().sequence([
      () =>
        yup
          .string()
          .matches(/^1[0-9]{10}$/, 'Phone number must be 10 digits')
          .required('Phone number is required'),
      () =>
        yup
          .string()
          .test(
            'isUnique',
            'An account with this phone number already exists',
            async (value: string) => {
              return await testPhone(value)
            }
          ),
    ]),
    source: yup.string().required('Please select how you heard about us'),
    desiredPosition: yup.string().required('Please specify current position'),
  })

export const getSignupDefaultVariantSchemaStepOne = (
  testEmail: (email: string) => Promise<boolean>,
  testPhone: (phone: string) => Promise<boolean>
) => getSignupSchemaAllFields(testEmail, testPhone).omit(['desiredPosition'])

export type SignupDefaultVariantSchemaStepOne = yup.InferType<
  ReturnType<typeof getSignupDefaultVariantSchemaStepOne>
>

export const signupDefaultVariantSchemaStepTwo = yup.object().shape({
  phoneNumberVerificationToken: yup
    .string()
    .min(5, 'Please enter the 5 digit code')
    .required('Verification code is required'),
})

export type SignupDefaultVariantSchemaStepTwo = yup.InferType<
  typeof signupDefaultVariantSchemaStepTwo
>

export const getSignupLandingVariantSchemaStepOne = (
  testEmail: (email: string) => Promise<boolean>,
  testPhone: (phone: string) => Promise<boolean>
) =>
  getSignupSchemaAllFields(testEmail, testPhone).omit([
    'source',
    'desiredPosition',
  ])

export type SignupLandingVariantSchemaStepOne = yup.InferType<
  ReturnType<typeof getSignupLandingVariantSchemaStepOne>
>

export const signupLandingVariantSchemaStepTwo =
  signupDefaultVariantSchemaStepTwo

export type SignupLandingVariantSchemaStepTwo = yup.InferType<
  typeof signupLandingVariantSchemaStepTwo
>

export const getSignupApplicationVariantSchemaStepOne =
  getSignupDefaultVariantSchemaStepOne

export type SignupApplicationVariantSchemaStepOne = yup.InferType<
  ReturnType<typeof getSignupApplicationVariantSchemaStepOne>
>

export const signupApplicationVariantSchemaStepTwo =
  signupDefaultVariantSchemaStepTwo

export type SignupApplicationVariantSchemaStepTwo = yup.InferType<
  typeof signupApplicationVariantSchemaStepTwo
>

export const getSignupAdminOnboardVariantSchemaStepOne = (
  testEmail: (email: string) => Promise<boolean>,
  testPhone: (phone: string) => Promise<boolean>
) =>
  getSignupSchemaAllFields(testEmail, testPhone).omit([
    'name',
    'source',
    'location',
    'desiredPosition',
  ])

export type SignupAdminOnboardVariantSchemaStepOne = yup.InferType<
  ReturnType<typeof getSignupAdminOnboardVariantSchemaStepOne>
>

export const signupAdminOnboardVariantSchemaStepTwo =
  signupDefaultVariantSchemaStepTwo

export type SignupAdminOnboardVariantSchemaStepTwo = yup.InferType<
  typeof signupAdminOnboardVariantSchemaStepTwo
>

export const getSignupEndorsementVariantSchemaStepOne = (
  testEmail: (email: string) => Promise<boolean>,
  testPhone: (phone: string) => Promise<boolean>
) => getSignupSchemaAllFields(testEmail, testPhone).omit(['source'])

export type SignupEndorsementVariantSchemaStepOne = yup.InferType<
  ReturnType<typeof getSignupEndorsementVariantSchemaStepOne>
>

export const signupEndorsementVariantSchemaStepTwo =
  signupDefaultVariantSchemaStepTwo

export type SignupEndorsementVariantSchemaStepTwo = yup.InferType<
  typeof signupAdminOnboardVariantSchemaStepTwo
>
