import { useCallback, useEffect, useMemo } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import { FindInAppOnboardingDialogQuery } from 'types/graphql'

import { toast } from '@redwoodjs/web/toast'

import {
  Button,
  Dialog,
  Field,
  FieldGroup,
  Fieldset,
  Label,
  RequiredAsterisk,
  Text,
} from 'src/atoms'
import SalaryPrefix from 'src/components/Forms/SalaryPrefix'
import SalarySuffix from 'src/components/Forms/SalarySuffix'
import { defaultOnboardingDialogTotalSteps } from 'src/components/InAppOnboardingDialogCell/DefaultOnboardingDialog/DefaultOnboardingDialog'
import RadioYearsOfExperienceRangeCell from 'src/components/YearsOfExperienceRange/RadioYearsOfExperienceRangeCell'
import { useUpdateProfile } from 'src/hooks/mutations/useUpdateProfile'
import { payBasisOptions } from 'src/lib/constants'
import { mixpanelUtils } from 'src/lib/utils/mixpanel/mixpanel'
import { RHFInput, RHFSelect } from 'src/reactHookForm'
import {
  InAppOnboardingUpdateProfileschema,
  inAppOnboardingUpdateProfileschema,
} from 'src/schemas'

type Props = {
  profile: FindInAppOnboardingDialogQuery['profile']
}

export function YearsOfExperienceAndPayDialog({ profile }: Props) {
  const [updateProfile, { loading }] = useUpdateProfile({
    onError: (error) => {
      toast.error(error.message)
    },
  })

  // Send Mixpanel event for step when the component initially renders.
  useEffect(() => {
    const sessionStorageKey = `onboarding_step${defaultOnboardingDialogTotalSteps}`
    if (sessionStorage.getItem(sessionStorageKey)) {
      mixpanelUtils.trackUserRevisitingOnboardingStep(
        defaultOnboardingDialogTotalSteps
      )
    } else {
      sessionStorage.setItem(sessionStorageKey, 'true')
      mixpanelUtils.trackOnboardingStep(defaultOnboardingDialogTotalSteps)
    }
  }, [])

  const defaultValues = useMemo(() => {
    return {
      currentPay: profile?.currentPay || '',
      desiredPay: profile?.desiredPay || '',
      payBasis: profile?.payBasis || '',
      yoeRangeId: profile?.yoeRangeId || '',
    }
  }, [profile])

  const methods = useForm({
    defaultValues,
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver<InAppOnboardingUpdateProfileschema>(
      inAppOnboardingUpdateProfileschema
    ),
  })

  const watchPayBasis = methods.watch<'payBasis'>('payBasis')

  const handleSubmit = useCallback(
    async (input: InAppOnboardingUpdateProfileschema) => {
      updateProfile({
        variables: {
          input,
        },
        onCompleted: () => {
          mixpanelUtils.updateMixpanelProfile({
            yoeRangeId: input.yoeRangeId,
            currentPay: input.currentPay || 0, // To be consistent with how it's set when signing up.
            desiredPay: input.desiredPay,
          })
          mixpanelUtils.trackOnboardingSuccess()
          window.location.reload()
        },
      })
    },
    [updateProfile]
  )

  return (
    <Dialog
      className="p-6"
      onClose={() => {}} // This prevents the dialog from being manually closed.
      size="2xl"
      open
    >
      <div className="flex flex-col gap-12">
        <div>
          <Text className="text-2xl" weight="bold">
            Welcome to Laborup!
          </Text>
          <Text className="text-md text-gray-900">
            The information below helps us find the right fit for you
          </Text>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)}>
            <Fieldset>
              <FieldGroup>
                <Field>
                  <Label>
                    Years of manufacturing experience
                    <RequiredAsterisk />
                  </Label>
                  <RadioYearsOfExperienceRangeCell name="yoeRangeId" />
                </Field>
                <Field>
                  <Label>
                    Are you paid on an hourly or yearly basis?
                    <RequiredAsterisk />
                  </Label>
                  <RHFSelect name="payBasis" placeholder="Select a pay basis">
                    {payBasisOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </RHFSelect>
                </Field>
                <Field>
                  <Label>Current pay</Label>
                  <RHFInput
                    type="number"
                    name="currentPay"
                    placeholder={
                      watchPayBasis ? '' : 'Select a pay basis above'
                    }
                    disabled={!watchPayBasis}
                    suffix={
                      watchPayBasis && <SalarySuffix payBasis={watchPayBasis} />
                    }
                    prefix={watchPayBasis && <SalaryPrefix />}
                  />
                </Field>
                <Field>
                  <Label>
                    Desired pay
                    <RequiredAsterisk />
                  </Label>
                  <RHFInput
                    type="number"
                    name="desiredPay"
                    placeholder={
                      watchPayBasis ? '' : 'Select a pay basis above'
                    }
                    disabled={!watchPayBasis}
                    prefix={watchPayBasis && <SalaryPrefix />}
                    suffix={
                      watchPayBasis && <SalarySuffix payBasis={watchPayBasis} />
                    }
                  />
                </Field>
              </FieldGroup>
              <div className="flex justify-end mt-6">
                <Button
                  className="w-full md:w-auto"
                  disabled={loading}
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </Fieldset>
          </form>
        </FormProvider>
      </div>
    </Dialog>
  )
}
