import Intercom, { show, shutdown } from '@intercom/messenger-js-sdk'

import { InitType } from '@intercom/messenger-js-sdk/dist/types'

export const intercomUtils = {
  launchIntercom: (props?: Partial<InitType>) => {
    Intercom({
      app_id: 'z3dqe1ik', // This could be an environment variable but probably not necessary.
      hide_default_launcher: true,
      ...props,
    })
  },

  openIntercom: (props?: Partial<InitType>) => {
    // Check if Intercom has already been booted.
    if (window.Intercom) {
      show()
    } else {
      intercomUtils.launchIntercom(props)
      show()
    }
  },

  shutdownIntercom: () => {
    shutdown()
  },
}
