const sizes = ['sm', 'md', 'lg', 'xl', '2xl'] as const

export type ScreenSize = (typeof sizes)[number]

export const screenSize: Record<ScreenSize, number> = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
}
