import { routes } from '@redwoodjs/router'

import EditIndustriesCell from 'src/components/Industry/EditIndustriesCell'
import { Footer } from 'src/components/ResumeImport/CompleteProfile/steps/Footer'
import { Header } from 'src/components/ResumeImport/CompleteProfile/steps/Header'

export function IndustriesStep({
  handleNext,
  initialRecommendedIndustriesNames,
  isLastStep,
}: {
  handleNext: () => void
  initialRecommendedIndustriesNames: string[]
  isLastStep: boolean
}) {
  return (
    <div className="flex flex-col gap-6">
      <Header title="Did we get your industries right?" />
      <EditIndustriesCell
        initialRecommendedIndustryNames={initialRecommendedIndustriesNames}
        recommendedIndustriesTitle="Suggestions from your resume"
      />
      <Footer
        handleNext={() => {
          if (isLastStep) {
            // Hard navigate to the profile page to refetch all of the relevant queries and close the modal.
            window.location.replace(routes.profile())
          } else {
            handleNext()
          }
        }}
        isLastStep={isLastStep}
        loading={false}
      />
    </div>
  )
}
