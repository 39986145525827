import React, { Fragment } from 'react'

import {
  Popover as HeadlessPopover,
  PopoverButton as HeadlessPopoverButton,
  PopoverPanel as HeadlessPopoverPanel,
  Transition as HeadlessTransition,
} from '@headlessui/react'
import clsx from 'clsx'

type Side = 'left' | 'right' | 'top' | 'bottom'

const transitionOpenClassNames: Record<Side, string> = {
  left: 'translate-x-0',
  right: 'translate-x-0',
  top: 'translate-y-0',
  bottom: 'translate-y-0',
}

const transitionClosedClassNames: Record<Side, string> = {
  left: '-translate-x-full',
  right: 'translate-x-full',
  top: '-translate-y-full',
  bottom: 'translate-y-full',
}

const enterDuration = 'duration-200'
const leaveDuration = 'duration-150'
const enterTimingFunction = 'ease-out'
const leaveTimingFunction = 'ease-in'

const panelTransitionClassNames = {
  enter: clsx('transition', enterDuration, enterTimingFunction),
  enterFrom: '',
  enterTo: '',
  leave: clsx('tansition', leaveDuration, leaveTimingFunction),
  leaveFrom: '',
  leaveTo: '',
}

const overlayTransitionClassNames = {
  enter: clsx('transition', enterDuration, enterTimingFunction),
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
  leave: clsx('tansition', leaveDuration, leaveTimingFunction),
  leaveFrom: 'opacity-100',
  leaveTo: 'opacity-0',
}

const getPanelTransitionClassNames = (side: Side) => {
  return {
    ...panelTransitionClassNames,
    enterFrom: clsx(
      panelTransitionClassNames.enterFrom,
      transitionClosedClassNames[side]
    ),
    enterTo: clsx(
      panelTransitionClassNames.enterTo,
      transitionOpenClassNames[side]
    ),
    leaveFrom: clsx(
      panelTransitionClassNames.leaveFrom,
      transitionOpenClassNames[side]
    ),
    leaveTo: clsx(
      panelTransitionClassNames.enterTo,
      transitionClosedClassNames[side]
    ),
  }
}

const panelClassNames: Record<Side, string> = {
  left: 'h-screen left-0 top-0 w-[80vw]',
  right: 'h-screen right-0 top-0 w-[80vw]',
  top: 'w-screen right-0 top-0 h-[80vh]',
  bottom: 'w-screen right-0 bottom-0 h-[80vh]',
}

export function Drawer({
  target,
  children,
  side = 'right',
  panelClassName,
  className,
}: {
  target?: React.ReactNode
  children?: React.ReactNode
  side?: Side
  panelClassName?: string
  className?: string
}) {
  const panelTransitionClasses = getPanelTransitionClassNames(side)
  return (
    <HeadlessPopover className={className}>
      {({ open }) => (
        <>
          <HeadlessPopoverButton className="rounded-lg data-[focus]:outline-primary-700 data-[focus]:outline data-[focus]:outline-2 data-[focus]:outline-offset-2">
            {target}
          </HeadlessPopoverButton>
          <HeadlessTransition
            show={open}
            as={Fragment}
            enter={overlayTransitionClassNames.enter}
            enterFrom={overlayTransitionClassNames.enterFrom}
            enterTo={overlayTransitionClassNames.enterTo}
            leave={overlayTransitionClassNames.leave}
            leaveFrom={overlayTransitionClassNames.leaveFrom}
            leaveTo={overlayTransitionClassNames.leaveTo}
          >
            <div className="fixed inset-0 flex w-screen justify-center overflow-y-auto bg-blackAlpha-600 px-2 py-2 focus:outline-0 sm:px-6 sm:py-8 lg:px-8 lg:py-16" />
          </HeadlessTransition>

          <HeadlessTransition
            show={open}
            enter={panelTransitionClasses.enter}
            enterFrom={panelTransitionClasses.enterFrom}
            enterTo={panelTransitionClasses.enterTo}
            leave={panelTransitionClasses.leave}
            leaveFrom={panelTransitionClasses.leaveFrom}
            leaveTo={panelTransitionClasses.leaveTo}
          >
            <HeadlessPopoverPanel
              static
              className={clsx('fixed', panelClassNames[side], panelClassName)}
            >
              {children}
            </HeadlessPopoverPanel>
          </HeadlessTransition>
        </>
      )}
    </HeadlessPopover>
  )
}
