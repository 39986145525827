import {
  EmploymentStatus,
  Profile,
  YearsOfExperienceRange,
} from '@prisma/client'

import { routes } from '@redwoodjs/router'
import { CellSuccessProps } from '@redwoodjs/web'

import { Badge, BadgeColor, Button, PencilIcon, Text } from 'src/atoms'
import AvatarFileCell from 'src/components/AvatarFile/AvatarFileCell'
import { Shimmer } from 'src/components/Shimmer/Shimmer'
import { useScreenSize } from 'src/hooks/useResize'

type FindProfileQuery = {
  profile:
    | (Pick<
        Profile,
        | 'id'
        | 'firstName'
        | 'lastName'
        | 'desiredPosition'
        | 'location'
        | 'desiredPay'
        | 'payBasis'
      > & {
        employmentStatus: Pick<EmploymentStatus, 'title'> | null
        yearsOfExperienceRange: Pick<YearsOfExperienceRange, 'range'> | null
      })
    | null
}

export const QUERY = gql`
  query FindProfileQuery {
    profile: profile {
      id
      firstName
      lastName
      desiredPosition
      location
      desiredPay
      payBasis
      employmentStatus {
        title
      }
      yearsOfExperienceRange {
        range
      }
    }
  }
`

const capitalizeFirst = (string?: string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : ''
}

/**
 * Further information about a profile that is available on larger screens.
 */
const DesktopAdditionalInformation = ({
  profile,
}: CellSuccessProps<FindProfileQuery>) => {
  if (
    !profile.yearsOfExperienceRange &&
    !profile.location &&
    !(profile.payBasis && profile.desiredPay)
  ) {
    return null
  }

  return (
    <div className="flex-col items-center gap-4 pt-4 border-t border-gray-400 hidden md:flex">
      {profile.yearsOfExperienceRange && (
        <div className="flex flex-col items-center gap-[2px]">
          <Text weight="medium" className="text-sm text-gray-900">
            Experience
          </Text>
          <Text weight="semibold" className="text-md">
            {profile.yearsOfExperienceRange.range} years of experience
          </Text>
        </div>
      )}
      {profile.location && (
        <div className="flex flex-col items-center gap-[2px]">
          <Text weight="medium" className="text-sm text-gray-900">
            Desired work location
          </Text>
          <Text weight="semibold" className="text-md">
            {profile.location}
          </Text>
        </div>
      )}
      {profile.payBasis && profile.desiredPay && (
        <div className="flex flex-col items-center gap-[2px]">
          <Text weight="medium" className="text-sm text-gray-900">
            Desired pay
          </Text>
          <Text weight="semibold" className="text-md">
            ${profile.desiredPay}{' '}
            {profile.payBasis === 'Yearly' ? 'per year' : 'per hour'}
          </Text>
        </div>
      )}
    </div>
  )
}

export const Loading = () => {
  return (
    <>
      <div className="flex">
        <Shimmer className="h-10 w-12 me-2" />
        <Shimmer className="h-10" />
      </div>
      <div className="flex w-full items-end justify-between gap-2">
        <Shimmer className="h-10 mt-2" />
      </div>
    </>
  )
}

export const Empty = () => <div>Profile not found</div>

export const Failure = ({ error }) => (
  <div className="rw-cell-error">{error?.message}</div>
)

export const Success = ({ profile }: CellSuccessProps<FindProfileQuery>) => {
  const screenSize = useScreenSize()
  const isScreenSmall = screenSize === 'sm'

  const name = `${capitalizeFirst(profile?.firstName || '')} ${capitalizeFirst(
    profile?.lastName || ''
  )}`

  const nameComponent = (
    <Text weight="semibold" className="text-md text-gray-950">
      {name}
    </Text>
  )

  const positionComponent = (
    <Text weight="semibold" className="text-sm text-gray-900">
      {profile?.desiredPosition || ''}
    </Text>
  )

  const jobComponent = profile?.employmentStatus?.title ? (
    <Badge className="mt-3" color={BadgeColor.gray}>
      {profile.employmentStatus.title}
    </Badge>
  ) : null

  const buttonComponent = (
    <Button href={routes.editBasicInfo()} className="" size="sm" outline isIcon>
      <PencilIcon className="text-gray-950 size-4" />
    </Button>
  )
  return (
    <div className="relative flex flex-col gap-8">
      <div className="flex flex-col items-center">
        <AvatarFileCell
          size={isScreenSmall ? 'size-32' : 'size-40'}
          className="my-2 md:mt-0"
        />
        {nameComponent}
        {positionComponent}
        <div className="flex items-end gap-3">
          {jobComponent}
          {!isScreenSmall && buttonComponent}
        </div>
        {isScreenSmall && (
          <div className="absolute top-0 right-0">{buttonComponent}</div>
        )}
      </div>
      <DesktopAdditionalInformation profile={profile} />
    </div>
  )
}
