import { useEffect } from 'react'

import { navigate, routes } from '@redwoodjs/router'

import { useAuth } from 'src/auth'

const AuthenticatedLayout = ({ children }) => {
  const { currentUser } = useAuth()
  const { isAuthenticated } = useAuth()
  useEffect(() => {
    if (!isAuthenticated) {
      navigate(routes.login())
    }
  }, [currentUser, isAuthenticated])

  return <>{children}</>
}

export default AuthenticatedLayout
