import {
  BatchCreateWorkExperiencesMutation,
  BatchCreateWorkExperiencesMutationVariables,
} from 'types/graphql'

import { TypedDocumentNode, useMutation } from '@redwoodjs/web'

const BATCH_CREATE_WORK_EXPERIENCES_MUTATION: TypedDocumentNode<
  BatchCreateWorkExperiencesMutation,
  BatchCreateWorkExperiencesMutationVariables
> = gql`
  mutation BatchCreateWorkExperiencesMutation(
    $inputs: [CreateWorkExperienceInput!]!
  ) {
    batchCreateWorkExperiences(inputs: $inputs) {
      id
    }
  }
`

export function useBatchCreateWorkExperiencesMutation() {
  return useMutation(BATCH_CREATE_WORK_EXPERIENCES_MUTATION)
}
